import React from "react";
import { useNavigate } from "react-router-dom";
import flight from "../../../../assets/images/Home/flight2.jpg";
function Cta() {
  const navigate = useNavigate();
  return (
    <section
      className="py-20"
      style={{
        backgroundImage: `url(${flight})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundColor: "rgba(59, 103, 81, 0.6)",
      }}
    >
      <div className="container">
        <div className="row text-center">
          <h1 className="font-semibold text-secondary  text-5xl pb-4 py-4">
            Did not find what you are looking for?
          </h1>
          <p className="mt-4">
            <span className="cta-btn  " onClick={() => navigate("/chopper")}>
              View all Packages
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Cta;
