import { z } from "zod";

export const bookingSchema = z.object({
  email: z
    .string({ invalid_type_error: "Email is required" })
    .nonempty({ message: "Email is required" })
    .email(),
  name: z
    .string({ invalid_type_error: "Name is required" })
    .nonempty({ message: "Name is required" })
    .min(2, "Name needs to be longer"),
  services: z
    .string({ invalid_type_error: "Service is required" })
    .nonempty({ message: "Service is required" })
    .min(2, "Service needs to be longer"),
  phone: z
    .string({ invalid_type_error: "Phone Number is required" })
    .nonempty({ message: "Phone Number is required" })
    .regex(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Phone number is not valid"
    ),

  message: z
    .string({ invalid_type_error: "Message is required" })
    .nonempty({ message: "Message is required" })
    .min(2, "Name needs to be longer"),
});

export const messageSchema = z.object({
  email: z
    .string({ invalid_type_error: "Email is required" })
    .nonempty({ message: "Email is required" })
    .email(),
  name: z
    .string({ invalid_type_error: "Name is required" })
    .nonempty({ message: "Name is required" })
    .min(2, "Name needs to be longer"),
  phone: z
    .string({ invalid_type_error: "Phone Number is required" })
    .nonempty({ message: "Phone Number is required" })
    .regex(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Phone number is not valid"
    ),

  message: z
    .string({ invalid_type_error: "Message is required" })
    .nonempty({ message: "Message is required" })
    .min(2, "Name needs to be longer"),
});
