import React from "react";
import BookingDetail from "./BookingDetail";
import BookingFormComponent from "../../../../components/BookingForm";
import Form from "./Form";

function BookingForm() {
  return (
    <div className="bg-[#055160] mt-20 ">
      <div className="container flex flex-col md:flex-row justify-between md:items-center text-light py-10 gap-20">
        <Form />
        <BookingDetail />
      </div>
    </div>
  );
}

export default BookingForm;
