import React, { useContext } from "react";
import "./style.css";
import BookingContext from "../../../../state";
function CarouselItem({
  title,
  content,
  image,
}: {
  title: string;
  content: string;
  image: any;
}) {
  const { toggleBooleanValue } = useContext(BookingContext)!;
  return (
    <div>
      <div className="carousel-inner">
        <div
          className="carousel-item"
          style={{
            background: `url(${image}) center center  no-repeat`,
            height: "75vh",
            opacity: "10",
          }}
        >
          <div className="carousel-inner">
            <div className="banner-content flex flex-col gap-5 items-center justify-center">
              <h1 className="text-4xl font-semibold text-center">{title}</h1>
              <p className="text-center">{content}</p>
              <a
                className="cta-btn !w-fit"
                onClick={() => toggleBooleanValue("show")}
              >
                {" "}
                Book Now!
              </a>
            </div>
          </div>
        </div>
      </div>
      ;
    </div>
  );
}

export default CarouselItem;
