import React from "react";
import Layout from "../../layout";
import ServiceBanner from "./Components/Banner";
import OverView from "./Components/OverView";
import Description from "./Components/Description";
import HighLights from "./Components/Highlights";
import Includes from "./Components/Includes";
import Gallery from "./Components/Gallery";
import { useParams } from "react-router-dom";
import useObjectBySlug from "../../hooks/useObjectBySlug";
import { chopperData } from "../../constants/chopper";

function Services() {
  const params = useParams();
  const data = useObjectBySlug(chopperData, params?.id as string);
  return (
    <Layout>
      <ServiceBanner
        title={data?.title}
        intro={data?.intro}
        image={data?.image}
      />
      <OverView overview={data?.overview} />
      <Description description={data?.description} />
      {data?.highLights &&
        Array.isArray(data?.highLights) &&
        data?.highLights?.length > 0 && (
          <HighLights highLights={data?.highLights} />
        )}
      <Includes includes={data?.includes} excludes={data?.excludes}/>
      <Gallery gallery={data?.gallery} />
    </Layout>
  );
}

export default Services;
