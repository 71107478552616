import React, { useContext } from "react";
import rescue from "../../../assets/images/Home/chopper/heli-rescue.jpg";
import BookingContext from "../../../state";

function ContactBox() {
  const { showForm, toggleBooleanValue } = useContext(BookingContext)!;

  return (
    <div className="relative">
      <div
        className="contact-by-call mb-4 top-3 mt-2"
        style={{
          background: `url(${rescue}) `,
          backgroundSize: "100% 100%",
        }}
      >
        <div className="content">
          <h1 className="font-semibold text-5xl">Contact via Call</h1>
          <p className="font-semibold text-2xl mt-3"> +977 9851153937</p>
          <p className="font-semibold text-2xl mt-3">OR</p>
          <a
            className="cta-btn mt-3"
            onClick={() => toggleBooleanValue("show")}
          >
            Make a Booking
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContactBox;
