import React from "react";

function Map() {
  return (
    <div className="my-10">
      <iframe
        title="Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14128.222963105758!2d85.3434771!3d27.7155652!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb197027f092c9%3A0x891fd9382e17b9b2!2sAero%20Trust%20Travel%20and%20Tours!5e0!3m2!1sen!2snp!4v1696780755541!5m2!1sen!2snp"
        width="100%"
        height="450"
        style={{ border: 0 }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default Map;
