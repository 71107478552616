import React from "react";
import { useNavigate } from "react-router-dom";

function TravelCard({ ...travel }) {
  const navigate = useNavigate();
  return (
    <div
      className="w-full  pr-4 pl-4 mt-10 cursor-pointer flex"
      onClick={() => navigate(`/travel/${travel?.slug}`)}
    >
      <div className="top-destinations-card-2 linked-cards  rounded-xl">
        <div className="h-[250px] overflow-hidden rounded-t-xl">
          <img
            src={travel?.image}
            className="travel-card-image h-full object-cover w-full"
          />
        </div>
        {travel?.discount && (
          <span className="price-tag bg-primary px-4 py-1 text-light transition-all">
            {travel?.discount}% Off
          </span>
        )}
        <div className="mb-0 py-4 px-2">
          <div className="card-desc px-1 cursor-pointer">
            <h4
              className="font-bold text-xl mt-2 mb-2"
              style={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {travel?.title}
            </h4>
            <div className="mb-3 text-grey flex items-center justify-between">
              <div className="flex items-center gap-1">
                <i className="fa-regular fa-clock" /> {travel?.days} days
              </div>
              <div className="flex items-center gap-1">
                <i className="fa fa-child" /> {travel?.minAge} +
              </div>
              <div className="flex items-center gap-1">
                <i className="fa fa-user" /> {travel?.minPag} people
              </div>
            </div>
            <p
              className="text-grey text-style text-start"
              style={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 4,
              }}
            >
              {travel?.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TravelCard;
