import helicopter from "../assets/images/banner-heli-sightseeing.jpg";
import HeliImage from "../assets/images/heli image .jpg";
import Chopper from "../assets/images/Home/Banner/choppers2.jpg";
import domesticFlight from "../assets/images/Home/Banner/domesticFlight.jpg";
import exploreNepal from "../assets/images/Home/Banner/exploreNepal.jpg";
import flight from "../assets/images/Home/passTicket.jpg";
import Flight from "../assets/images/Home/Banner/flight5.jpg";
import Heli from "../assets/images/Home/helicopture.jpg";
// import expolre from "../assets/images/Home/explore.jpg";
import expolre from "../assets/images/Home/Banner/nepal.jpg";
export const bannerItems = [
  {
    id: 1,
    title: "Domestic Flights",
    content:
      "Discover the Heart of the Himalayas. Book your next domestic flight and Experience through the breathtaking landscapes of Nepal like never before!",
    image: Flight,
  },
  {
    id: 2,
    title: "Discover Choppers",
    content:
      "Whether you're seeking thrilling adventures or involved in a rescue mission, experience the wonder of Nepal's mountains from a bird's eye view with precision and speed. Discover the extraordinary with us.",
    image: Chopper,
  },

  {
    id: 3,
    title: "Explore Nepal",
    content:
      "Discover Nepal's hidden gems, vibrant traditions, and breathtaking landscapes with our curated 'Explore Nepal' experiences. Join us for unforgettable adventures and unlock the essence of this diverse land.",
    image: exploreNepal,
  },
];

export const homeServices = [
  {
    id: 1,
    title: "Air Tickets",
    description:
      "Welcome to our premier domestic ticket service in Nepal, your gateway to seamless travel in the Himalayas. Explore cultural wonders in Kathmandu, trek Everest, or discover Pokhara. Our team ensures a hassle-free booking process for a smooth journey across Nepal.",
    image: flight,
    redirect: "domesticflight",
  },
  {
    id: 2,
    title: "Helicopters Services",
    description:
      "Soar above Himalayan peaks with Aero Trust, offering unmatched helicopter experiences in Nepal. Customize your adventure, charter a private helicopter or options for shared heli journeys. Beyond tourism, we stand as a beacon of safety with a specialized team for rescue operations.",
    image: Heli,
    redirect: "chopper",
  },
  {
    id: 3,
    title: "Explore Nepal",
    description:
      "Embark on an extraordinary adventure with Explore Nepal, offering curated moments from cultural exploration to thrilling activities like paragliding. Our handpicked experiences promise an irresistible blend of thrill and discovery, turning every journey into vibrant memories.",
    image: expolre,
    redirect: "travel",
  },
];

export const travelAndTrek = [
  {
    id: 1,
    title: "Everest Base Camp Trek",
    days: 7,
    discount: "10",
    startPrice: "$300",
    image:
      "https://www.atlasandboots.com/wp-content/uploads/2019/05/Best-treks-in-Nepal-%E2%80%93-Three-passes-trek-Gokyo-Ri.jpg",
    description:
      "Everest stands tall with its beauty radiating across the Khumbu region. Traveling to the Everest Base Camp (EBC) must be a dream for almost all trekkers. Everest is undoubtedly worth the title “Trekker’s Paradise” for its immense beauty. ",
  },
  {
    id: 2,
    title: "Everest Base Camp Trek",
    days: 7,
    startPrice: "$300",
    image:
      "https://www.atlasandboots.com/wp-content/uploads/2019/05/Best-treks-in-Nepal-%E2%80%93-Three-passes-trek-Gokyo-Ri.jpg",
    description:
      "Everest stands tall with its beauty radiating across the Khumbu region. Traveling to the Everest Base Camp (EBC) must be a dream for almost all trekkers. Everest is undoubtedly worth the title “Trekker’s Paradise” for its immense beauty. ",
  },
  {
    id: 3,
    title: "Everest Base Camp Trek",
    days: 7,
    discount: "6",
    startPrice: "$300",
    image:
      "https://www.atlasandboots.com/wp-content/uploads/2019/05/Best-treks-in-Nepal-%E2%80%93-Three-passes-trek-Gokyo-Ri.jpg",
    description:
      "Everest stands tall with its beauty radiating across the Khumbu region. Traveling to the Everest Base Camp (EBC) must be a dream for almost all trekkers. Everest is undoubtedly worth the title “Trekker’s Paradise” for its immense beauty. ",
  },
];

export const blogs = [
  {
    id: 1,
    title: "Everest Base Camp Trek",
    image:
      "https://www.atlasandboots.com/wp-content/uploads/2019/05/Best-treks-in-Nepal-%E2%80%93-Three-passes-trek-Gokyo-Ri.jpg",
    description:
      "Embarking on a trek to the srcmountains is not merely a physical journey; it's a soul-stirring odyssey that beckons adventurers and nature enthusiasts alike. ",
  },
  {
    id: 2,
    title: "Everest Base Camp Trek",
    image:
      "https://www.atlasandboots.com/wp-content/uploads/2019/05/Best-treks-in-Nepal-%E2%80%93-Three-passes-trek-Gokyo-Ri.jpg",
    description:
      "Embarking on a trek to the mountains is not merely a physical journey; it's a soul-stirring odyssey that beckons adventurers and nature enthusiasts alike. ",
  },
  {
    id: 3,
    title: "Everest Base Camp Trek",
    image:
      "https://www.atlasandboots.com/wp-content/uploads/2019/05/Best-treks-in-Nepal-%E2%80%93-Three-passes-trek-Gokyo-Ri.jpg",
    description:
      "Embarking on a trek to the mountains is not merely a physical journey; it's a soul-stirring odyssey that beckons adventurers and nature enthusiasts alike. ",
  },
];
