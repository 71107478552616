import HeloView from "../assets/images/Home/travel/helicopter-view.jpg";
import partan from "../assets/images/Home/travel/patandarbarsqure.jpg";
import elephant from "../assets/images/Home/travel/elephant.jpg";
import adventureSports from "../assets/images/Home/travel/adventureSports.jpg";
import birds from "../assets/images/Home/travel/birds.jpg";
import Hike from "../assets/images/Home/travel/hike.jpg";
export const trvelAndTrek = [
  {
    id: 1,
    title: "Adventure Sports Tour in Nepal",
    image: adventureSports,
    slug: "Adventure-Sports-Tour-in-Nepal",
    description: `Embark on an exhilarating adventure with Aero Trust Travel, your gateway to thrilling escapades in Nepal's Himalayas. Experience bungee jumps, rafting, mountain biking, paragliding, and ultralight flights. Conveniently located near Kathmandu and Pokhara, our adventures cater to ages 13 to 81, ensuring inclusivity. Beyond adrenaline, enjoy trekking, hiking, cultural exploration, and heartwarming encounters. With meticulous planning, Aero Trust guarantees comfort, safety, and vibrant social experiences. Choose our crafted trips or customize your own for an unforgettable adventure in Nepal's breathtaking landscapes.`,
    days: "1",
    minAge: "13",
    minPag: "2",
    includesTitle:
      "What to Expect on Nepal's Thrilling Adventure Sports Tour ?",
    includes: [
      "Bungee jumps, rafting, mountain biking, paragliding, and many more",
      "Iconic locations near Kathmandu and Pokhara, offering amazing views.",
      "Tailored for ages 13 to 81, welcoming participants of all fitness levels.",
      "	Meticulous Planning",
      "Budget-friendly options and customizable trip choices.",
      "Vibrant Social Atmosphere",
      "Join a community of like-minded adventurers, fostering camaraderie.",
    ],
    example: [
      {
        id: 1,
        title: "Bungee Jump in Nepal",
        des: "Take the plunge into exhilaration! Nepal's heart-pounding bungee jump awaits, promising an adrenaline surge amidst breathtaking landscapes.",
      },
      {
        id: 2,
        title: "Everest Scenic Mountain Flight",
        des: "Soar to the top of the world! Immerse yourself in awe-inspiring vistas of Everest and the Himalayas with a scenic mountain flight, a journey where the sky meets the summit.",
      },
      {
        id: 3,
        title: "Mountain Bike Ride to Upper Mustang",
        des: "Conquer the rugged beauty of Upper Mustang on two wheels! Embark on a mountain bike ride, an adventure weaving through ancient landscapes and cultural treasures.",
      },
      {
        id: 4,
        title: "Paragliding in Nepal",
        des: "Glide through the clouds and savor the freedom of flight! Paragliding in Nepal offers a bird's-eye view of majestic landscapes, an experience that transcends the ordinary.",
      },
      {
        id: 5,
        title: "River Rafting Adventure",
        des: "Navigate the wild currents! Join a river rafting adventure for an adrenaline-fueled journey down Nepal's rivers, where excitement meets the rush of nature.",
      },
    ],
    gallery: [],
  },
  {
    id: 2,
    title: "A Day Hike in Kathmandu",
    image: Hike,
    slug: "A-Day-Hikein-Kathmandu",
    description: `Escape the urban hustle and embrace the tranquility of nature with Aero Trust's meticulously curated hiking
    adventures around Kathmandu. Our selected trails weave a captivating tapestry, seamlessly blending cultural
    exploration with invigorating trekking experiences. Through lush forests and along stone-paved steps, these hikes
    ascend to elevated vantage points, revealing breathtaking views of the Kathmandu Valley and the majestic
    Himalayan ranges. Embark on a day of tranquility and natural splendor with Aero Trust Travel and Tours on the Hike. Offering
    panoramic glimpses from the summit, stone-paved steps create a relaxed ascent through dense forests, revealing
    clear views of the valley. En route, encounter cultural gems like Nagi Gompa, a Buddhist monastery, and Bagdwar,
    the source of the Bagmati River. Our carefully curated Kathmandu Hiking Expedition includes captivating destinations like Champadevi, Nagarjun
    Jamcho, Phulchoki, and Shivapuri, each promising a unique blend of cultural encounters, natural beauty, and
    invigorating trails. Trust Aero Trust to elevate your hiking experience, ensuring a day of exploration, rejuvenation,
    and unparalleled vistas. Explore the beauty that lies just beyond the bustling cityscape with Aero Trust – where every
    step is a promise of adventure and trust.
    `,
    days: "1",
    minAge: "3",
    minPag: "2",
    includesTitle: "Why Hiking is Important ?",
    includes: [
      "Improves cardiovascular health.",
      "Enhances overall fitness, stamina, and endurance.",
      "Reduces stress, anxiety, and depression.",
      "Promotes a sense of well-being and mental clarity.",
      "Connects individuals with like-minded outdoor enthusiasts.",
      "Offers a sense of adventure and exploration.",
      "Supports personal growth through overcoming obstacles.",
      "Appreciates the ever-changing scenery and natural elements.",
      "Enhances balance, coordination, and posture.",
      "Fosters a sense of accomplishment and self-confidence.",
      "Supports personal growth through overcoming obstacles.",
    ],
    example: [
      {
        id: 1,
        title: "Champadevi Day Hike Package",
        des: `Embark on an enchanting journey with our Champadevi Day Hike Package, a trail
        that promises both cultural discoveries and invigorating trekking experiences. Ascend through stone-paved steps,
        meandering through lush forests, and reach the summit for panoramic views, capturing the essence of Kathmandu's
        natural splendor.`,
      },
      {
        id: 2,
        title: "Nagarjun Jamcho Day Hike",
        des: `Uncover the allure of Nagarjun Jamcho with our immersive day hike. Traverse scenic
        landscapes, encounter cultural gems, and enjoy breathtaking views of the Himalayas. This carefully curated hike
        offers a perfect blend of nature, spirituality, and rejuvenation.`,
      },
      {
        id: 3,
        title: "Phulchoki Day Hiking Package",
        des: `Revel in the tranquility of Phulchoki with our Day Hiking Package. As you ascend
        through the verdant surroundings, catch glimpses of diverse flora, birdlife, and the snow-capped Himalayan peaks.
        This expedition promises a day filled with natural beauty and invigorating trails.`,
      },
      {
        id: 4,
        title: "Shivapuri Day Hike Package",
        des: `Embark on a rejuvenating journey with our Shivapuri Day Hike Package. Traverse
        the pristine trails of Shivapuri, where dense forests and clear views of the valley create a serene backdrop. This hike
        combines cultural encounters, natural beauty, and a refreshing escape from the urban bustle.`,
      },
    ],
    gallery: [],
  },
  {
    id: 3,
    title: "Jungle Safari In Nepal",
    image: elephant,
    slug: "Jungle-Safari-In-Nepal",
    description: `Embark on an extraordinary adventure with our Jungle Safari in Nepal, where the heart of the Terai region unveils its untamed wonders.
    Discover the enchanting Chitwan National Park, a UNESCO World Heritage Site, teeming with one-horned rhinoceros, elusive Bengal tigers,
    and vibrant birdlife. Venture off the beaten path to Bardia National Park, a pristine wilderness in western Nepal, where the Karnali River hosts
    playful dolphins and rare wildlife. Our safari offers more than wildlife encounters; it's an immersive experience into the rich biodiversity of Nepal.
    Engage in thrilling elephant safaris, guided nature walks, and cultural programs with local Tharu communities. Let our expert naturalists guide
    you through dense forests, providing insights into the fascinating world of the jungle. Elevate your adventure with canoeing, bird watching, and
    authentic cultural experiences, ensuring every moment is a snapshot of the wild and captivating beauty of Nepal's jungles. Trust us to make
    your Jungle Safari an unforgettable journey into the heart of nature
    `,
    days: "3",
    minAge: "6",
    minPag: "2",
    includesTitle: "What to expect in jungle safari adventure ?",
    includes: [
      "Traverse dense forests and grasslands to spot Bengal tigers, one-horned rhinoceros, and a variety of wildlife unique to each reserve.",
      "Cruise along the scenic rivers—Karnali in Bardia, Rapti in Chitwan, and Koshi in Koshi Tappu—for a different perspective of the jungle's beauty.",
      "Discover an array of bird species, from vibrant kingfishers to majestic eagles, making these reserves a paradise for bird enthusiasts.",
      "Immerse yourself in the local Tharu culture with village tours, experiencing their traditions and hospitality.",
      "Gain insights into elephant conservation efforts with visits to elephant breeding centers, contributing to wildlife preservation.",
      "Embark on thrilling jeep safaris, traversing diverse landscapes and increasing your chances of encountering elusive wildlife.",
      "Witness the magical hues of sunrise and sunset over the rivers, creating unforgettable moments in the heart of nature.",
    ],
    example: [
      {
        id: 1,
        title: "Bardia Jungle Adventure",
        des: `Embark on a thrilling escapade into the untouched wilderness of Bardia National Park, a
        hidden gem in western Nepal. This jungle adventure promises encounters with rare wildlife, including Royal Bengal
        tigers, wild elephants, and exotic bird species. Traverse dense sal forests, grassy plains, and meandering rivers as
        expert naturalists guide you through this pristine habitat, offering an off-the-beaten-path safari experience.`,
      },
      {
        id: 2,
        title: "Chitwan Jungle Safari",
        des: `Step into the heart of the Terai region and explore the iconic Chitwan National Park, a
        UNESCO World Heritage Site renowned for its diverse flora and fauna. Our Chitwan Jungle Safari promises
        unforgettable moments with one-horned rhinoceros, Bengal tigers, and a myriad of birdlife. Engage in elephant
        safaris, jeep excursions, and guided nature walks, immersing yourself in the vibrant jungle atmosphere and authentic
        cultural experiences.`,
      },
      {
        id: 3,
        title: "Koshi Tappu Wildlife Reserve:",
        des: `Embark on a unique wildlife journey to Koshi Tappu, a lesser-known but equally
        captivating destination. This wildlife reserve, nestled in the eastern Terai, is a haven for birdwatchers and nature
        enthusiasts. Immerse yourself in the serene beauty of wetlands, encountering a plethora of migratory birds, water
        buffaloes, and Gangetic dolphins. Koshi Tappu Wildlife Reserve offers a tranquil escape and a chance to witness the
        raw beauty of Nepal's diverse ecosystems.`,
      },
    ],
    gallery: [],
  },
  {
    id: 4,
    title: "Everest Scenic Flight Tour",
    image: HeloView,
    slug: "Everest-Scenic-Flight-Tour",
    description: `Embark on a mesmerizing one-hour Everest Scenic Flight with Aero Trust Travel and Tours, offering breathtaking views
    of the majestic Himalayan ranges, including the iconic Mount Everest. This thrilling experience provides a unique opportunity to
    witness the world's highest peaks, serene lakes, and glaciers from the comfort of your guaranteed window seat. The Everest
    Scenic Flight is an ideal adventure for nature and adventure enthusiasts, capturing the beauty of the Everest region in a compact
    timeframe.
    `,
    days: "1",
    minAge: "12",
    minPag: "2",
    includesTitle: "Tour Highlights",
    includes: [
      "Embark on an extraordinary journey through the Himalayas, soaring over majestic snow-capped mountains. Experience the thrill of witnessing the Everest region from a unique perspective, capturing the breathtaking  beauty of the world's highest peaks.",
      "Immerse yourself in the grandeur of the Himalayas as you enjoy panoramic views of the dramatic landscapes, vast glaciers, and winding valleys below. This aerial adventure promises a visual feast of nature's wonders.",
      "Elevate your experience with a guaranteed window seat, ensuring an unobstructed view throughout the flight. Maximize your enjoyment as you gaze upon the awe-inspiring scenery, making every moment a memorable one.",
    ],
    example: [
      {
        id: 1,
        title: "Mt. Everest",
        des: `Marvel at the tallest mountain on Earth, standing at 8848.86 meters, offering spectacular views of Himalayan
        peaks, lakes, and glaciers.`,
      },
      {
        id: 2,
        title: "Mt. Gaurishankar",
        des: `Admire this sacred peak named after Hindu gods Gauri and Shankar, providing cultural significance to
        the region.`,
      },
      {
        id: 3,
        title: "Mahalangur Himal Range",
        des: `Explore this mountain range, home to Everest, Cho Oyu, Lhotse, and Makalu, offering a unique
        perspective of the Upper Himalayas.`,
      },
      {
        id: 4,
        title: "Gokyo Lakes",
        des: `Witness the highest freshwater lake in the world, surrounded by the beauty of the Everest region.`,
      },
      {
        id: 5,
        title: "Khumbu Region",
        des: `Discover the panoramic views of over 90 Himalayan vistas, trekking routes, and the Sagarmatha National
        Park.`,
      },
    ],
    gallery: [],
  },
  {
    id: 5,
    title: "Kathmandu Seven World Heritage Tour",
    image: partan,
    slug: "Kathmandu-Seven-World-Heritage",
    description: `Embark on a captivating exploration of Kathmandu's cultural and historical treasures with our Kathmandu's Heritage
    Day Tour. In just one day, immerse yourself in the rich heritage of the Kathmandu Valley as you visit seven
    UNESCO World Heritage Sites. From the ancient palaces and temples of Kathmandu, Patan, and Bhaktapur to the
    spiritual sanctuaries like Pashupatinath and Boudhanath, this tour offers a seamless blend of history, architecture,
    and spirituality. Accompanied by expert guides and with the convenience of private transportation, Aero Trust Travel
    and Tours ensures a memorable and insightful journey, unveiling the heart of Nepal's cultural legacy in a single day.
    `,
    days: "1",
    minAge: "3",
    minPag: "2",
    includesTitle: "Tour Highlights",
    includes: [
      "Wander the Durbar Squares in Kathmandu, Bhaktapur, and Patan – three UNESCO World Heritage Sites adorned with historic palaces, temples, and enchanting courtyards.",
      "Immerse yourself in the divine aura at Pashupatinath, a Hindu temple on the Bagmati River, where spirituality meets the flowing waters.",
      "Stand in awe before the colossal Boudhanath Stupa, a beacon of Tibetan Buddhism and one of the world's largest stupas, inviting you into its spiritual embrace.",
      "Ascend to Swayambhunath Stupa, the renowned Monkey Temple, offering not just spiritual solace but also breathtaking panoramas of Kathmandu.",
      " Explore the historical Changu Narayan Temple, a testament to Nepal's earliest Hindu temples, adorned with intricate wood carvings and ancient inscriptions.",
      "Uncover the artistic legacy of Patan, renowned for its exquisite metalwork, stunning architecture, and a cultural tapestry that unfolds with every step.",
      "Roam the historic streets of Bhaktapur, a city steeped in rich culture, adorned with traditional Newari architecture, ceramics, and captivating narratives.",
      "Peek into the Kumari Ghar, the Living Goddess's residence at the heart of Kathmandu's Durbar Square, where myths and reality converge in a captivating dance.",
    ],
    example: [
      {
        id: 1,
        title: "Kathmandu Durbar Square",
        des: `Begin your journey at Kathmandu Durbar Square, also known as Hanuman Dhoka
        Palace. Explore the former Malla and Shah royal palace, surrounded by temples like Taleju Mandir and the Nine-
        Story Basantapur Tower.`,
      },
      {
        id: 2,
        title: "Pashupati Nath Temple",
        des: `Visit the grand Pashupatinath Temple complex, a UNESCO World Heritage Site. Witness
        the pagoda-style main temple, adorned with gold-plated roofing, and explore the spiritual ambiance along the banks
        of the Holy River Bagmati.`,
      },
      {
        id: 3,
        title: "Boudhanath Stupa",
        des: `Discover the impressive Boudhanath Stupa, a 36-meter-tall mandala surrounded by gompas.
        Experience the aromatic atmosphere of incense and the fluttering prayer flags, making it a significant Buddhist
        pilgrimage site.`,
      },
      {
        id: 4,
        title: "Swayambhunath Stupa",
        des: `Explore the ancient Swayambhunath Stupa, also known as the Monkey Temple. Uncover
        the rich history and myths surrounding this hilltop stupa, where Buddhist stupas and Hindu temples coexist.`,
      },
      {
        id: 5,
        title: "Patan Durbar Square",
        des: `Visit the former royal palace of Patan/Lalitpur at Patan Durbar Square. Admire the Newari
        craftsmanship and explore Hindu and Buddhist monuments, including the Mahaboudha Temple and Kumbeshwor
        Temple.`,
      },
      {
        id: 5,
        title: "Bhaktapur Durbar Square",
        des: `Experience the charm of Bhaktapur, the City of Devotees. Explore the 55-window
        palace, Nyatapola temple, and artistic pillars showcasing Nepali culture, tradition, and religion.`,
      },
      {
        id: 5,
        title: "Changunarayan Temple",
        des: `Conclude your day at the ancient Changunarayan Temple, adorned with stunning stone
        and wood sculptures. Marvel at Lord Vishnu's depictions and explore the artistic and architectural prowess of ancient
        Nepal.`,
      },
    ],
    gallery: [],
  },
  {
    id: 6,
    title: "Birds of the Valley 1-Day Kathmandu Bird Watching tour",
    image: birds,
    slug: "Birds-of-the-Valley",
    description: `Nepal, renowned for its rich tapestry of cultures and awe-inspiring landscapes, stands as a sanctuary for a myriad of migratory bird species.
    Step away from the vibrant pulse of Kathmandu as you embark on our exclusive 1-day Bird Watching Tour. Submerge yourself in the tranquility
    of nature, serenaded by the harmonious melodies of captivating birds. A treasure trove of cultural diversity and natural marvels, Nepal beckons
    bird enthusiasts to explore its avian wonders through our meticulously designed 1-day Bird Watching Tour in Kathmandu. Immerse yourself in
    the peaceful embrace of nature, where the vibrant flutter of wings and the melodious tunes of chirping birds introduce you to a captivating world
    of feathered marvels
    `,
    days: "1",
    minAge: "12",
    minPag: "2",
    includesTitle: "Why Bird Watching in Kathmandu?",
    includes: [
      "Explore diverse ecosystems in the Kathmandu Valley, from dense forests to panoramic hilltops, providing ideal habitats for a wide array of bird species.",
      "Witness rare and elusive birds during our tours, from the mysterious Spiny Babbler to the majestic Greater Yellownape and enchanting Blue-naped Pitta.",
      "Immerse yourself in untouched beauty as our tours take you to unspoiled areas within the Kathmandu Valley, offering a tranquil environment for bird observation.",
      "Enhance your adventure with our knowledgeable guides, gaining insights into bird behaviors, habitats, and distinct calls.",
      "Capture the beauty of diverse bird species against scenic landscapes, creating last in memories and stunning photographs.",
      "Tailor your experience by choosing from various destinations, ensuring a personalized and fulfilling adventure.",
    ],
    example: [
      {
        id: 1,
        title: "Shivapuri National Park",
        des: `Shivapuri National Park is a birdwatcher's paradise, boasting a rich diversity of 318
        bird species. Among the rare sightings are the elusive Spiny Babbler, Hoary-throated Barwing, and White-
        throated Tit. Explore the park to encounter the Hill Partridge, Great Barbet, Eurasian Jay, and a myriad of other
        fascinating avian residents.`,
      },
      {
        id: 2,
        title: "Phulchowki Hill",
        des: `Home to approximately 300 bird species, Phulchowki Hill offers birdwatchers a haven for
        rare sightings, including the Blue-naped Pitta and Spectacled Finch. The vibrant birdlife includes the Rufous-
        gorget Flycatcher, Yellow-browed Tit, and numerous warblers, making every visit a captivating experience.`,
      },
      {
        id: 3,
        title: "Godavari Forest & Botanical Garden",
        des: `With an allure for almost 200 bird species, the Godavari Forest and
        Botanical Garden provide a tranquil setting for birdwatching. Common sightings encompass the Rufous-gorget
        Flycatcher and Whiskered Yuhina, offering enthusiasts an ideal location for a peaceful and rewarding bird-
        watching experience.`,
      },
      {
        id: 4,
        title: "Nagarjun Hill",
        des: `Hosting around 35 bird species, Nagarjun Hill presents opportunities to spot unique birds such
        as the Northern Eagle Owl, Red-billed Blue Magpie, and Maroon Oriole. Unexplored areas within Nagarjun Hill
        may reveal even more hidden treasures, adding an element of discovery to your birdwatching expedition.`,
      },
    ],
    gallery: [],
  },
];
