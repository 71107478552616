import React from "react";

function IterinaryContent({ iterinary }: any) {
  return (
    <div className="iterinary-content">
      <h5 className="mb-4 text-2xl font-bold">What Services We Offer ?</h5>
      <ul className="list-unstyled">
        {iterinary?.map((item: any) => {
          return (
            <li key={item?.id} className="mt-2">
              <h6 className="mb-1 font-semibold text-lg">{item.title}</h6>
              <p className="text-base !font-normal">{item?.des}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default IterinaryContent;
