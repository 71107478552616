import funuru from "../assets/images/Home/Team/furbu_sir.jpg";
import dolma from "../assets/images/Home/Team/dolma.jpg";
import pralhad from "../assets/images/Home/Team/pralhda.jpg";
import nabin from "../assets/images/Home/Team/nabin.jpg";
import aashish from "../assets/images/Home/Team/ashish.jpg";

const teamMember = [
  {
    id: 2,
    name: "Dolma Sherpa ",
    position: "Chairman ",
    description:
      "Meet Dolma Sherpa, the exceptional Chairwan of Aero Trust Travel and Tours. Hailing from the heart of Solukhumbu, Dolma's unwavering dedication and hard work have played very important role in elevating the company to its esteemed position. Her commitment to fostering a sense of family among the staff has created a workplace that feels like a genuine home away from home, where each team member is valued and connected. Dolma's positive attitude is a guiding light, creating an environment where challenges are met with smiles and optimism. Her kindness and caring spirit serve as the heartbeat of the team, laying the foundation for collaboration and shared success. Beyond her exceptional leadership, Dolma's cheerfulness and love for plants infuse the office with vibrant energy, turning Aero Trust into a dynamic community.Her versatile contributions have not only shaped the company but have also cultivated a destination for growth and achievement. Dolma Sherpa's impressive leadership and infectious positivity have turned Aero Trust into a place where dreams take flight and aero trust is always thankful towards her invaluable contributions.",

    image: dolma,
    reverse: true,
  },
  {
    id: 1,
    name: "Funuru Sherpa ",
    position: "Managing Director",
    description:
      "Meet Funuru Sherpa, the force propelling Aero Trust Travel and Tours to the new heights.Growing up in the heart of Solukhumbu, Nepal, Funuru's roots are entwined with the stunning landscapes that now define his business. With over 12 years of leadership, he has guided the company with simplicity and an unwavering commitment to ensuring clients' happiness.Funuru's connection to Sherpa culture and the travel industry runs deep. Beyond his role as Managing Director, he's a Sherpa-born visionary with an inherent love for the mountains. Notably, Funuru spent over 19 years as a Station Manager at Lukla Airport, showcasing not only his managerial skills but also a profound understanding of the unique challenges and opportunities in the Everest region. His extensive experience includes significant roles and connections with prominent aviation companies such as Yeti Air, Tara Air, Summit Air, and helicopter companies like Heli Everest, Altitude Air, and Kailash Heli Service.Today, Funuru's company acts as the vital link between airlines and travel partners, specializing in providing services to trekking agencies and expedition organizers Beyond mere business. His leadership is a blend of industry knowledge, a commitment to safety, and a genuine desire to give back to the community.",
    image: funuru,
    reverse: false,
  },

  {
    id: 3,
    name: "Pralhad Basnet",
    position: "Financial Advisor",
    description:
      "Introducing Pralhad Basnet, the dedicated financial consultant at Aero Trust. Originally from Dhading, Pralhad has been an integral part of the organization for the past six years. With unwavering commitment, he consistently provides sound financial advice, particularly excelling in areas such as tax strategies and navigating government regulations. Mr. Basnet's expertise adds immeasurable value to Aero Trust, ensuring the soundness of company financial strategies.",

    image: pralhad,
    reverse: true,
  },
  {
    id: 4,
    name: "Nabin Basnet",
    position: "Sales Excutive ",
    description:
      "Meet Nabin Basnet, the backbone of Aero Trust Travel and Tours, with over six years of dedicated service to the company. Originating from the enchanting landscapes of Dhading, Nabin embodies loyalty and commitment. His unwavering dedication has proven essential to the organization, significantly contributing to Aero Trust's growth and success. Nabin's reliability and friendly demeanor have earned him the respect and admiration of colleagues and clients alike. As a Sales Executive, he goes beyond his role, adopting a customer-centric approach, understanding individual needs, and delivering personalized solutions. Nabin's excellent communication skills and service knowledge make him a trusted advisor for those seeking memorable and tailor-made journeys. His positive impact extends beyond transactions, fostering positive relationships and a collaborative atmosphere within the team. Nabin's commitment aligns seamlessly with Aero Trust's mission to create unparalleled travel experiences. As a key player, his enthusiasm and professionalism make him an invaluable asset, and Aero Trust looks forward to achieving greater heights together in the world of travel and adventure, appreciating Nabin's dedication.",

    image: nabin,
    reverse: false,
  },
];

export default teamMember;
