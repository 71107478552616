import helicoptorFlying from "../assets/images/Home/travel/helicoptor_flying.jpg";
import mountainsWithriver from "../assets/images/Home/travel/mountainwithreiver.jpg";
import mountainnTrek from "../assets/images/Home/travel/mountain_trek.jpg";
import panaromaTrek from "../assets/images/Home/travel/panaromaTrek.jpg";
import luklAirport from "../assets/images/Home/travel/lukla.jpg";
import festival from "../assets/images/Home/travel/festival.jpg";

export const BlogsData = [
  {
    id: 1,
    title:
      "Aero Trek Tales: Unraveling Nepal's Beauty Through Travel and Flight",
    slug: "Aero-trek-tales",
    description: [
      {
        id: 1,
        title: "",
        description:
          "Nepal, a land of enchantment and breathtaking landscapes, offers a unique blend of cultural richness and natural wonders. In the pursuit of capturing the essence of this Himalayan haven, embark with us on a journey through Aero Trek Tales – a captivating exploration of Nepal's beauty through the lens of travel and flight.",
      },
      {
        id: 2,
        title: "Discovering Nepal's Largest Lake: Rara's Tranquil Waters",
        description:
          "Our first chapter unfolds at Rara Lake, nestled in the heart of Rara National Park. The largest lake in Nepal, Rara is a testament to untouched beauty. Join us as we navigate the pristine waters, surrounded by the awe-inspiring landscapes of the national park. The convenience and safety of a helicopter ride make this remote paradise accessible in a single day, offering a truly exclusive experience.",
      },
      {
        id: 3,
        title: "Navigating the Peaks: Skybound Adventures in the Himalayas",
        description:
          "Delve into the unparalleled adventure of trekking and flying over the majestic peaks of the Himalayas. From the iconic Annapurna Range to the hidden gems of Kaphuche, Sikles, Dhampus, and Ghandruk, our Aero Trek Tales will guide you through the diverse landscapes, providing insights into the rich tapestry of Nepal's natural wonders.",
      },
      {
        id: 4,
        title: "The Ultimate Guide to Nepal's Travel Treks and Flights",
        description:
          "Are you planning your next escapade to Nepal? Our comprehensive guide unravels the secrets of travel treks and flights, offering tips on planning, packing, and choosing the best routes. Whether you're an adventure seeker or a nature enthusiast, Nepal's diverse terrain has something extraordinary to offer, and our guide ensures you make the most of your journey.",
      },
      {
        id: 5,
        title: "Flight Paths and Mountain Trails: A Harmonious Blend",
        description:
          "Experience the harmonious blend of flight paths and mountain trails as we recount tales of exploration and discovery. We'll take you on a visual journey, capturing the breathtaking landscapes, serene lakes, and lush forests from both the skies and the trails. Discover how the convenience of helicopter flights complements the immersive experience of trekking.",
      },
      {
        id: 6,
        title: "",
        description:
          "Join us on Aero Trek Tales as we unfold the beauty of Nepal, weaving stories of travel, trekking, and flight into a tapestry of unforgettable adventures. Let the sky become your exclusive pathway to unparalleled exploration in this Himalayan wonderland.",
      },
    ],
    tags: ["#AeroTrustAndTravel", "#RaraLakes"],
    image: helicoptorFlying,
  },
  {
    id: 2,
    title: "About Nepal",
    slug: "about-nepal",
    description: [
      {
        id: 1,
        title: "",
        description: `Nestled between the giants of India and China, Nepal is a landlocked marvel that captivates with its unique blend of
          culture, history, and breathtaking landscapes. Spanning an area of 147,181 square kilometers and home to
          approximately 22 million people, Nepal boasts a rich tapestry of traditions that have withstood the test of time.`,
      },
      {
        id: 2,
        title: "The Resilient History:",
        description: `While neighboring India and Tibet were under British rule, Nepal held onto its sovereignty, a
testament to the courage of the Gorkha Armies, earning the country the moniker &quot;Land of Brave Gorkhas.&quot; The
resilient spirit of the Nepali people shines through their unique culture, history, and religion.`,
      },
      {
        id: 3,
        title: "Cultural Harmony:",
        description: `Despite being predominantly Hindu, Nepal is a shining example of religious harmony, with
Hinduism and Buddhism coexisting harmoniously. The country celebrates its diverse cultural landscape, with
festivals serving as a sacred ritual that unites people from various ethnic backgrounds.`,
      },
      {
        id: 4,
        title: "A Tapestry of Diversity:",
        description: `Geographically, Nepal can be divided into three distinct regions. The southern lowlands,
  known as the Terai, cover 17% of the total surface area and boast a hot, tropical climate. Home to almost half of the
  population, the Terai region is rich in agriculture and dense forests, with a culture dominated by Mithila traditions.
  The central hill areas enjoy a cooler sub-tropical climate, while the northern region experiences an alpine climate.
  The Himalayan region, covering 16% of the total land, is a haven for adventure enthusiasts, climbers, and nature
  lovers. The diverse topography offers a glimpse into the unique cultures of the Mahabharat and Churia ranges and
  the Tibetan-influenced Himalayan region.`,
      },
      {
        id: 5,
        title: "Ethnic Mosaic:",
        description: `Nepal's pride lies in its peaceful coexistence of various ethnic groups. Sherpas, Gurung, Magar, and
Tamang predominantly reside in the hilly regions, each contributing to the rich cultural mosaic. In the middle lands
and valleys, Brahmins and Chhetris live alongside other minorities, fostering a harmonious blend of traditions. In the
Terai, the Tharus take center stage as the main ethnic group.`,
      },
      {
        id: 6,
        title: "Exploring the Heart of Nepal:",
        description: `Kathmandu, the vibrant capital, stands as the hub of political and cultural activities.
        With ministries, diplomatic missions, and universities, it epitomizes the beating heart of the nation. Pokhara, another
        jewel in Nepal's crown, enchants visitors with its scenic beauty, surrounded by majestic mountains and tranquil
        lakes, making it a must-visit tourist destination. Industrial cities like Biratnagar and Birjung contribute to the economic
        vitality of the nation.`,
      },
      {
        id: 7,
        title: "Conclusion:",
        description: `Nepal, with its rich heritage, geographical diversity, and cultural harmony, beckons adventurers,
        cultural enthusiasts, and nature lovers alike. From the towering peaks of the Himalayas to the vibrant streets of
        Kathmandu, Nepal stands as a hidden gem, waiting to be discovered and cherished by those seeking an
        unforgettable journey into the heart of the Himalayas.`,
      },
    ],
    tags: ["#Nepal", "#Exploring_Nepal"],
    image: mountainsWithriver,
  },
  {
    id: 3,
    title: "Festivals in Nepal: Known and Unknown Celebrations",
    slug: "festivals-in-nepal",
    description: [
      {
        id: 1,
        title: "",
        description: `Nepal, often recognized as a paradise for trekkers, unveils another facet of its charm through a rich
        tapestry of festivals. Beyond its awe-inspiring landscapes, Nepal is a melting pot of cultural diversity, and no matter
        when you visit, you're likely to encounter the vibrant celebrations that echo through its cities and valleys. While not all
        festivals are national holidays, their importance is etched deeply in the hearts of the enthusiastic Nepali people.
        Celebrations in Nepal range from masked dances to grand tugs-of-war, creating a brilliant spectacle for onlookers.
        For those seeking a journey back in time to the Middle Ages, festivals like Chaitra Dasain, Bada Dashain, Bisket
        Jatra, Indra Jatra, Rato Machhendranath, and Seto Machhendranath showcase impressive chariots parading
        through the lively streets of Kathmandu, Asan, Bhaktapur, and Patan.`,
      },
      {
        id: 2,
        title: "Nature of Festivals in Nepal:",
        description: `Nepali festivals generally follow the lunar calendar, making it challenging to predict
        exact celebration dates well in advance. Despite this, we've compiled a list of major festivals in Nepal, categorized by
        the months they typically fall in, providing a glimpse into the diverse cultural celebrations that color the Nepali lunar
        months.`,
      },
      {
        id: 3,
        title: "Dashain (In October):",
        description: `Dashain, the main festival for Nepalese, is celebrated for 11 consecutive days in October. It
        marks the victory of Goddess Durga over the demon Mahisasur, symbolizing the triumph of virtues over demonic
        forces. The festival includes various rituals such as Ghatasthapana, Phool Pati, Mahaastami, Nawami, and Vijaya
        Dashami.`,
      },
      {
        id: 4,
        title: "Tihar, Brother &amp; Sister Festival (November):",
        description: `Tihar, also known as the festival of light and flowers, is celebrated for
        five days in November. Each day is dedicated to the worship of different animals and deities, including crows, dogs,
        cows, and the goddess of wealth, Laxmi. Bhai Tika, the final day, involves brothers and sisters worshiping each
        other.`,
      },
      {
        id: 5,
        title: "Maghe Sangranti (Mid-January):",
        description: `Maghe Sangranti, observed in mid-January, involves people taking a sacred bath
        in rivers like Trisuli, Narayani, and Baghmati. Hindus and Buddhists alike participate in homage to Lord Vishnu, and
        families enjoy a hearty meal symbolizing the transition to longer and warmer days.`,
      },
      {
        id: 6,
        title: "Shree-Panchami (February):",
        description: `Shree Panchami, also known as Saraswoti Puja, falls on February 13. This day holds
        special significance for students who worship the goddess of learning and knowledge, Saraswati. Parents take
        toddlers to temples and engage in cultural and religious activities.`,
      },
      {
        id: 7,
        title: "Lhosar (February):",
        description: `Lhosar, celebrated from February 9 to 11, marks the Tibetan New Year. Sherpas, Gurung, and
        Tibetan communities partake in festivities, adorning Buddha Stupa and Swayambhu stapes with decorations. The
        celebration includes throwing roasted barley flour into the air as a symbol of welcoming the New Year.`,
      },
      {
        id: 7,
        title: "Conclusion:",
        description: `Nepal's festivals, whether widely recognized or tucked away in the corners of the nation, showcase the
        country's cultural richness, diversity, and reverence for tradition. From the grandeur of Dashain to the humor of
        Gaijatra, each celebration is a testament to the resilience and unity of the Nepali people. As you traverse the vibrant
        
        streets and serene landscapes during these festivals, you'll find yourself immersed in a world where tradition meets
        celebration, making Nepal truly a land of vibrant festivity.`,
      },
    ],
    tags: ["#Nepal", "#Festivals_Nepal"],
    image: festival,
  },
  {
    id: 4,
    title: "Lukla Airport: A Gateway to Adventure Amidst the Himalayas",
    slug: "lukla-airport",
    description: [
      {
        id: 1,
        title: "",
        description: `Nestled amidst the awe-inspiring Himalayas, Lukla Airport stands as a testament to both engineering ingenuity and
        the spirit of adventure. Serving as the main gateway to the Everest region, this small yet vital airstrip has earned a
        reputation as one of the world's most challenging and thrilling airports.`,
      },
      {
        id: 2,
        title: "The Dramatic Landing:",
        description: `As you disembark from the aircraft, the grandeur of the Himalayas unfolds before
        you. Majestic peaks, including the iconic Mount Everest, surround the airport, creating a breathtaking panorama that
        leaves every visitor in awe.`,
      },
      {
        id: 3,
        title: "Gateway to Everest Treks:",
        description: `Lukla Airport is not merely a transportation hub; it's the starting point for those
        embarking on the legendary Everest Base Camp trek. Trekkers and mountaineers from around the globe use Lukla
        as their launchpad for the adventure of a lifetime, following in the footsteps of legendary climbers.`,
      },
      {
        id: 4,
        title: "Vibrant Sherpa Culture:",
        description: `Surrounded by the traditional Sherpa villages, Lukla offers a glimpse into the rich local
        culture. Wander through the bustling streets, adorned with prayer flags, and interact with the warm and friendly
        Sherpa people, gaining insights into their unique way of life.`,
      },
      {
        id: 5,
        title: "Challenges and Adventures: ",
        description: `The airport's challenging conditions, including unpredictable weather and the rugged
        terrain, add an element of thrill to the entire experience. Delayed flights and sudden changes in weather are not
        uncommon, turning every journey into an unpredictable adventure.`,
      },
      {
        id: 6,
        title: "A Pilgrimage for Trekkers:",
        description: `For many, landing at Lukla is akin to a pilgrimage. It marks the beginning of a journey
        filled with physical challenges, breathtaking landscapes, and a profound sense of accomplishment upon reaching the
        Everest Base Camp.`,
      },
      {
        id: 7,
        title: "Preserving the Legacy:",
        description: `Despite its challenges, Lukla Airport is a lifeline for the local communities, facilitating trade,
        tourism, and medical evacuations. Efforts are continually made to enhance its safety and infrastructure, ensuring it
        remains a beacon for adventurers worldwide.`,
      },
      {
        id: 7,
        title: "Conclusion:",
        description: `Lukla Airport is more than just a transit point; it's a symbol of human resilience and the indomitable
        spirit of those who dare to dream of reaching the top of the world. Whether you're a seasoned trekker or a thrill-
        seeker seeking a unique aviation experience, Lukla Airport promises an unforgettable adventure amidst the towering
        peaks of the Everest region.`,
      },
    ],
    tags: ["#Nepal", "#lukla"],
    image: luklAirport,
  },
  {
    id: 5,
    title: "Sherpa Chronicles: Living Legends of the Himalayas",
    slug: "sherpa-chronicles",
    description: [
      {
        id: 1,
        title: "",
        description: `The Himalayas, with their towering peaks and mystical allure, have long been a beacon for
        adventurers seeking to conquer nature's grandeur. Yet, amidst these majestic mountains, a community of unsung
        heroes quietly resides—the Sherpa people. In this exploration, we embark on a journey deep into the heart of the
        Himalayas to unravel the rich tapestry of Sherpa life, traditions, and their indispensable role in making Everest
        expeditions a reality.`,
      },
      {
        id: 2,
        title: "The Sherpa Legacy: A Glimpse into the Past:",
        description: `Begin by tracing the roots of the Sherpa people, shedding light on their ancient origins and the development of
        a unique culture intricately woven into the fabric of the Himalayan landscape.`,
      },
      {
        id: 3,
        title: "Himalayan Home: Life in the Shadow of Giants:",
        description: `Transport readers to the very heart of the Sherpa homeland, exploring their daily lives amidst the awe-inspiring
        surroundings of the Himalayas. Paint a vivid picture of their villages, customs, and the enduring connection
        they share with the mountains.`,
      },
      {
        id: 4,
        title:
          "Guardians of Everest: The Vital Role of Sherpas in Mountaineering:",
        description: `Delve into the critical role Sherpas play in the world of mountaineering. Unpack the history of their involvement,
        from being crucial members of early expeditions to becoming the backbone of modern-day climbs, ensuring
        the success and safety of those who aspire to conquer Everest.`,
      },
      {
        id: 5,
        title: "Sherpa Spirit: Resilience and Courage Amidst Adversity: ",
        description: `Highlight the unwavering spirit and resilience of the Sherpa people. Explore how their courage in the face of
        adversity, from harsh weather conditions to treacherous terrains, has not only defined their identity but has
        also inspired generations of climbers.`,
      },
      {
        id: 6,
        title: "Living Traditions: Sherpa Cultural Heritage:",
        description: `Immerse readers in the vibrant tapestry of Sherpa culture. Explore their unique traditions, festivals, and
        spiritual practices that have withstood the test of time, providing a profound sense of identity and purpose in
        the challenging Himalayan environment.`,
      },
      {
        id: 7,
        title: "Beyond Porters: Dispelling Misconceptions:",
        description: `Address common misconceptions about Sherpas, particularly the notion that they are mere porters. Showcase
        the diverse roles Sherpas play in mountaineering, from expert guides to invaluable contributors in the intricate
        logistics of Everest expeditions.`,
      },
      {
        id: 8,
        title: "The First Ascents: Sherpas at the Pinnacle of Achievement:",
        description: `Chronicle the historic first ascents of Everest by Sir Edmund Hillary and Tenzing Norgay Sherpa. Explore how
        this seminal moment not only marked a triumph for humanity but also elevated the status of Sherpas as living
        legends in the mountaineering world.`,
      },
      {
        id: 9,
        title: "Contemporary Sherpa Heroes: Record Holders and Inspirations:",
        description: `Spotlight modern Sherpa climbers who have etched their names in history, setting records for Everest ascents.
        Celebrate the achievements of climbers like Apa Sherpa, Phurba Tashi Sherpa, and Kami Rita Sherpa,
        highlighting their contributions to the legacy of Sherpa mountaineering.`,
      },
      {
        id: 9,
        title: "Mountaineering as a Way of Life:",
        description: `Unveil the profound connection between mountaineering and the Sherpa way of life. Explore the motivations
        behind their participation in Everest expeditions, from economic necessities to the inherent love and respect
        they hold for the mountains.`,
      },
      {
        id: 10,
        title: "Sherpa Chronicles: A Continuing Saga:",
        description: `Conclude by emphasizing that the Sherpa story is an ongoing saga, with each expedition and climber
        contributing to the living history of the Himalayas. Acknowledge the Sherpa people not just as guides or
        climbers but as living legends whose stories echo through the valleys and peaks of the majestic Himalayan
        range.`,
      },
    ],
    tags: ["#Nepal", "#sherpass"],
    image: mountainnTrek,
  },
  {
    id: 6,
    title: "Top 10 Treks in Nepal for 2024",
    slug: "top-10-treks",
    description: [
      {
        id: 1,
        title: "",
        description: `Nepal, a haven for trekkers worldwide, beckons with its diverse landscapes, majestic mountains, and cultural
        richness. From leisurely strolls to challenging ascents, the country offers a spectrum of trekking experiences. Your trekking
        adventure in Nepal can range from traversing lush valleys to conquering towering peaks, with options for both seasoned hikers and
        those seeking a more relaxed journey. To guide you through the best trekking experiences in 2024, we&#39;ve compiled a list of the top
        10 treks in Nepal, considering factors like cost, popularity, culture, and landscape.`,
      },
      {
        id: 2,
        title: "Everest Base Camp Trek:",
        description: `Trek in the shadows of the world&#39;s highest peak, Mount Everest. The Everest Base Camp Trek takes
        you through picturesque villages, Buddhist monasteries, and lush slopes. Offering stunning views of Everest, Lhotse, and Nuptse,
        this trek is a classic Himalayan adventure.`,
      },
      {
        id: 3,
        title: "Annapurna Base Camp Trek:",
        description: `Discover the Annapurna region&#39;s beauty with the Annapurna Base Camp Trek. This popular route
        showcases snow-capped peaks, Gurung settlements, and rejuvenating natural hot springs. The trek culminates at the base of the
        tenth-highest mountain in the world, Mount Annapurna.`,
      },
      {
        id: 4,
        title: "Langtang Valley Trek:",
        description: `Regarded as the best and easiest trek, Langtang Valley Trek unfolds scenic mountain communities,
        diverse vegetation, and authentic cultural experiences. This trek, rich in Tibetan traditions, takes you through narrow valleys and up
        to the breathtaking Kyanjin Gompa.`,
      },
      {
        id: 5,
        title: "Mardi Himal Trek: ",
        description: `For a short yet appealing trek in the Annapurna region, consider the Mardi Himal Trek. This less crowded
        route offers stunning views of Annapurna South, Fishtail, and Hiunchuli. Immerse yourself in Himalayan culture and natural beauty
        on this off-the-beaten-path adventure.`,
      },
      {
        id: 6,
        title: "Upper Mustang Trek:",
        description: `Explore the former Himalayan Kingdom of Mustang, a bastion of Tibetan culture. With fewer opportunities
        for up-close mountain views, this trek focuses on cultural backgrounds, arid landscapes, and the enchanting walled town of Lo
        Manthang.`,
      },
      {
        id: 7,
        title: "Manaslu Circuit and Tsum Valley Trek:",
        description: `Experience the thrills of the Manaslu Circuit, offering breathtaking views of Manaslu,
        Annapurna II, and other peaks. This trek includes a detour to the culturally rich Tsum Valley, providing an unforgettable mountain
        and nature experience.`,
      },
      {
        id: 8,
        title: "Makalu Barun Valley Trek:",
        description: `CFor experienced hikers and nature enthusiasts, the Makalu Barun Valley Trek is a long and
        challenging journey. Witness the world&#39;s fifth-highest peak, Mt. Makalu, and explore the Makalu Base Camp, surrounded by pristine
        landscapes.`,
      },
      {
        id: 9,
        title: "Ghorepani Poonhill Trek:",
        description: `Embark on one of the most popular short treks in Nepal, the Ghorepani Poonhill Trek. Witness
        breathtaking views of the Annapurna Range, including Mt. Fishtail, Mt. Nilgiri, and Mt. Dhaulagiri, from the vantage point of
        Poonhill.`,
      },
      {
        id: 10,
        title: "Annapurna Circuit Trek via Tilicho Lake:",
        description: `Considered one of the most beautiful traditional treks globally, the Annapurna Circuit
        via Tilicho Lake offers stunning trails, varied topography, and a diverse cultural experience. Explore the world&#39;s deepest gorge and
        one of the highest mountain lakes.`,
      },
      {
        id: 11,
        title: "Dolpo Trek/Upper Dolpo:",
        description: `Discover the most isolated trek in Nepal, the Dolpo Trek or Upper Dolpo. Trek to the untouched
        corners near Nepal&#39;s Tibetan border, exploring a remote and pristine region. Encounter the Shey Phoks`,
      },
    ],
    tags: ["#Nepal", "#sherpass"],
    image: panaromaTrek,
  },
];
