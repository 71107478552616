import React from "react";
import "./style.css";
import { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import logo from "../../assets/images/aerotrust-logo.png";
import { Dropdown, MenuProps, Space, message } from "antd";
import contacts from "../../constants/contacts";
const navItems = [
  "about us",
  "domestic flight",
  "chopper",
  "travel",
  "blogs",
  "contact",
];

function Nav() {
  const [toggle, toggleNav] = useState(false);
  const [clientWindowHeight, setClientWindowHeight] = useState(0);
  const [boxShadow, setBoxShadow] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const handleScroll = () => {
    setClientWindowHeight(window.scrollY);
  };

  useEffect(() => {
    let backgroundTransparacyVar = clientWindowHeight / 400;
    if (backgroundTransparacyVar < 1) {
      let boxShadowVar = backgroundTransparacyVar * 0.1;

      setBoxShadow(boxShadowVar);
    }
  }, [clientWindowHeight]);

  const showInMapClicked = () => {
    // window.open();
  };

  const items: MenuProps["items"] = [
    {
      label: "Domestic flight",
      key: "1",
    },
    {
      label: "Mountain Flight",
      key: "2",
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    navigate(`/flight/${key}`);
  };
  return (
    <div
      className="nav-container"
      style={{
        boxShadow: `rgb(0 146 44 / ${boxShadow}) 0px 0px 30px 8px`,
      }}
    >
      <div className="nav-info-container container">
        <div className="nav-info !hidden md:!flex">
          <a href={`mailto:${contacts?.email}}`}>
            <div className="flex gap-2 items-center text-primary text-base cursor-pointer transition-all duration-500  hover:text-[black]">
              {" "}
              <i className="fa-solid fa-envelope text-[17px] text-primary mt-1"></i>{" "}
              <h1>{contacts?.email}</h1>{" "}
            </div>
          </a>
          <div className="flex gap-2 items-center text-primary text-sm ">
            {" "}
            <i className="fa-solid fa-phone text-[17px] text-primary mt-1"></i>{" "}
            <h1> {contacts?.number}</h1>
          </div>
          <div
            className="flex gap-2 items-center text-primary text-base cursor-pointer transition-all duration-500 hover:text-[black]"
            onClick={showInMapClicked}
          >
            {" "}
            <i className="fa-solid fa-location-dot text-[17px] text-primary mt-1">
              {" "}
            </i>{" "}
            <h1> {contacts?.address}</h1>
          </div>
        </div>
      </div>
      <span className="w-full block h-[1px] bg-[#EAEAEA] w-[83.33%] mx-auto"></span>
      <nav className="nav-bar container">
        <Link to="/">
          <img src={logo} alt="" className="logo w-40" />
        </Link>
        <ul className="menu">
          <li className="item">
            <NavLink
              to={`/`}
              className={({ isActive }) => (isActive ? "link active " : "link")}
            >
              Home
            </NavLink>
          </li>
          {navItems.map((item, index) => {
            if (item === "flight") {
              return (
                <Dropdown
                  menu={{ items, onClick }}
                  trigger={["click", "hover"]}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <li className="item" key={index}>
                      <NavLink
                        to={`/${item.split(" ").join("")}`}
                        onClick={(e) => e.preventDefault()}
                        className={({ isActive }) =>
                          isActive ? "link active " : "link"
                        }
                      >
                        {item}
                      </NavLink>
                    </li>
                  </a>
                </Dropdown>
              );
            } else {
              return (
                <li className="item" key={index}>
                  <NavLink
                    to={`/${item.split(" ").join("")}`}
                    className={({ isActive }) =>
                      isActive ? "link active " : "link"
                    }
                  >
                    {item}
                  </NavLink>
                </li>
              );
            }
          })}
        </ul>

        <button
          className="nav-icon"
          style={{ zIndex: 4 }}
          onClick={() => toggleNav(!toggle)}
        >
          {<span className="line" />}
          <span
            className="line"
            style={{
              width: toggle ? "40%" : "70%",
            }}
          />
          <span className="line" />
        </button>
      </nav>

      <div className="overlay" style={{ height: toggle ? "100vh" : "0" }}>
        <ul
          className="overlay-menu"
          style={{
            transform: toggle ? "translateY(0%)" : "translateY(-200%)",
            transition: "all 0.4s ease-in-out",
          }}
        >
          <li
            className="text-link"
            // style={{ opacity: toggle ? "1" : "0",pointerEvents:toggle?"auto":"none" }}
          >
            <Link
              to={`/`}
              className="link"
              onClick={() => toggleNav((prev) => !prev)}
            >
              Home
            </Link>
          </li>

          {navItems.map((item, index) => {
            if (item === "flight") {
              return (
                <Dropdown
                  menu={{ items, onClick }}
                  trigger={["click", "hover"]}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <li className="text-link" key={index}>
                      <Link
                        to={`/${item.split(" ").join("")}`}
                        className="link flex items-center justify-center gap-3"
                        onClick={(e) => e.preventDefault()}
                      >
                        {item}
                        <i className="fa-solid fa-caret-down" />
                      </Link>
                    </li>
                  </a>
                </Dropdown>
              );
            } else {
              return (
                <li className="text-link" key={index}>
                  <Link
                    to={`/${item.split(" ").join("")}`}
                    className="link"
                    onClick={() => toggleNav((prev) => !prev)}
                  >
                    {item}
                  </Link>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
}

export default Nav;
