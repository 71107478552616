import React, { useContext } from "react";
import helicopter from "../../../../assets/images/banner-heli-sightseeing.jpg";
import GradidentCircle from "../../../../components/GradidentCircle";
import BookingContext from "../../../../state";

interface IBanner {
  title: string | undefined;
  intro: string | undefined;
  image: any;
}

function ServiceBanner({ title, image, intro }: IBanner) {
  const { toggleBooleanValue } = useContext(BookingContext)!;

  return (
    <div className="container relative flex items-center flex-col  md:flex-row gap-10 justify-between w-full h-full">
      <div
        className="gradident absolute "
        style={{ top: 0, right: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <div
        className="gradident absolute "
        style={{ bottom: -100, left: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <div className="content-section text-end flex flex-col items-center md:items-end gap-5 justify-center md:w-1/2">
        <h1 className="text-3xl md:text-5xl font-semibold text-center md:text-right">
          {title}
        </h1>
        <p className="md:pl-20 text-center md:text-end">{intro}</p>
        <button className="cta-btn" onClick={() => toggleBooleanValue("show")}>
          Book Now !
        </button>
      </div>
      <div className="image-container banner-image md:w-1/2 h-96 order-first md:order-last  rounded-xl ">
        <img src={image} className="!w-full object-cover" alt="" />
      </div>
    </div>
  );
}

export default ServiceBanner;
