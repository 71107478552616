import React from "react";
import Layout from "../../../layout";
import GradidentCircle from "../../../components/GradidentCircle";
import { chopperData } from "../../../constants/chopper";
import ChopperCard from "../Components/Card";
function ServicesList() {
  return (
    <Layout>
      <div className="mb-20">
        <div className="container relative">
          <div
            className="gradident absolute "
            style={{ top: 200, right: -350, zIndex: 4 }}
          >
            <GradidentCircle />
          </div>
          <div
            className="gradident absolute "
            style={{ bottom: -100, left: -350, zIndex: 4 }}
          >
            <GradidentCircle />
          </div>
          <div className="ml-5 md:mt-10 mb-10">
            <h1 className="fw-bold display-5 text-left text-3xl md:text-5xl font-semibold">
              Chopper Services
            </h1>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 w-full ">
            {chopperData?.map((service: any) => {
              return <ChopperCard key={service?.id} {...service} />;
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ServicesList;
