import React from "react";
import Layout from "../../layout";
import TravelCard from "../Home/Components/TravelAndTour/TravelCard";
import GradidentCircle from "../../components/GradidentCircle";
import { travelAndTrek } from "../../constants/home";
import { trvelAndTrek } from "../../constants/travel";
function TravelAndTreksList() {
  return (
    <Layout>
      <div className="container mb-20 relative">
        <div
          className="gradident absolute "
          style={{ top: 0, right: -350, zIndex: 4 }}
        >
          <GradidentCircle />
        </div>
        <div
          className="gradident absolute "
          style={{ bottom: -100, left: -350, zIndex: 4 }}
        >
          <GradidentCircle />
        </div>
        <div className=" ml-5 md:mt-10 ">
          <h1 className="fw-bold display-5 text-left text-3xl md:text-5xl font-semibold">
            Travel And Treks
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  w-full ">
          {trvelAndTrek.map((travel) => {
            return <TravelCard key={travel?.id} {...travel} />;
          })}
        </div>
      </div>
    </Layout>
  );
}

export default TravelAndTreksList;
