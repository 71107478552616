import { LoadingOutlined } from "@ant-design/icons";
import { Modal, Spin } from "antd";

function Loader({ isVisiable }: { isVisiable: boolean }) {
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin rev={null} />;
  return (
    <Modal open={isVisiable} footer={null} closable={false} centered>
      <div className="flex items-center justify-center py-20">
        <Spin indicator={antIcon} />
      </div>
    </Modal>
  );
}

export default Loader;
