import { Col, Divider, Row } from "antd";
import DestinationCard from "./destinationCard";
import { Slot, Trunk } from "../../../constants/flight";
function FlightType() {
  return (
    <div className="container mb-8">
      <h2 className="font-semibold mb-8 text-3xl ">Domestic Flight Routes</h2>
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12}>
          <h2 className="text-lg font-medium ">Trunk Sectors</h2>
          <p className="my-4">
            These medium-sized commercial planes are similar to those used for
            short-haul flight routes worldwide. Primarily employed for routes
            involving Kathmandu.
          </p>
          <Row gutter={[24, 24]}>
            {Trunk.map((flight) => {
              return <DestinationCard key={flight.id} {...flight} />;
            })}
          </Row>
        </Col>
        <Col xs={24} md={12} className="flex md:gap-5">
          <Divider type="vertical" className="h-full hidden md:block" />
          <div>
            <h2 className="text-lg font-medium ">STOL Sectors</h2>
            <p className="my-4">
              Smaller, highly maneuverable aircraft capable of taking off and
              landing on shorter runways. Typically used for flights to remote
              or mountainous areas.
            </p>
            <Row gutter={[24, 24]}>
              {Slot.map((flight) => {
                return <DestinationCard key={flight.id} {...flight} />;
              })}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default FlightType;
