import React from "react";
function Video() {
  return (
    <section className="container video relative mt-20">
      <div className="hero-underlay  w-full h-full bg-center rounded-xl !border-transparent  bg-cover bg-black">
        <video
          width="320"
          height="240"
          loop
          autoPlay
          muted
          className="w-full h-full object-cover rounded-xl !border-transparent "
          title="Introduction video of Stainless steel equipments manufacturer for commercial kitchen, dairy, and pharmaceutical Nepal."
        >
          <source src="/video/helivideo.mp4" type="video/mp4" />
          <source src="/video/helivideo.mp4" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>
  );
}

export default Video;
