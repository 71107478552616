import React from "react";
import { useNavigate } from "react-router-dom";

function BlogCard({ ...blog }) {
  const navigate = useNavigate();
  return (
    <div
      className="w-full pr-4 pl-4 mt-10   cursor-pointer flex"
      onClick={() => navigate(`/blogs/${blog?.slug}`)}
    >
      <div className="top-destinations-card-2 linked-cards rounded-xl">
        <div className="h-[300px] overflow-hidden rounded-t-xl">
          <img
            src={blog.image}
            alt=""
            className="travel-card-image h-full object-cover w-full "
          />
        </div>
        <div className="mb-0 py-4 px-2">
          <div className="card-desc px-1">
            <h4
              className="font-bold text-xl mt-2 mb-2"
              style={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {blog?.title}
            </h4>

            <p
              className="text-grey text-style"
              style={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 4,
              }}
            >
              {blog?.description?.[0].description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
