import React from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import About from "../pages/About";
import Home from "../pages/Home";
import "./App.css";
import "./custome.css";
import Services from "../pages/Services";
import ServicesList from "../pages/Services/list";
import BlogList from "../pages/Blogs";
import BlogsDetails from "../pages/Blogs/Details";
import TravelAndTreksList from "../pages/TravelAndTrek";
import TravelDetails from "../pages/TravelAndTrek/Details";
import Contact from "../pages/Contact";
import { BookingProvider } from "../state";
import FlightPage from "../pages/Flight";
import TermsAndConditions from "../pages/TermsAndCondtions";

function App() {
  return (
    <div className="App">
      <BookingProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutus" element={<About />} />
            <Route path="/blogs" element={<BlogList />} />
            <Route path="/blogs/:id" element={<BlogsDetails />} />
            <Route path="/chopper/:id" element={<Services />} />
            <Route path="/chopper" element={<ServicesList />} />
            <Route path="/travel" element={<TravelAndTreksList />} />
            <Route path="/travel/:id" element={<TravelDetails />} />
            <Route path="/domesticflight" element={<FlightPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
          </Routes>
        </BrowserRouter>
      </BookingProvider>
    </div>
  );
}

export default App;
