import React from "react";

function DetailTravel({ description }: any) {
  return (
    <div className="iterinary-content pb-7">
      {/* <h6 className="text-lg font-semibold">
        Everest is undoubtedly worth the title “Trekker’s Paradise”
      </h6> */}
      <p className="text-[16px] !font-normal">{description}</p>
    </div>
  );
}

export default DetailTravel;
