import React from "react";
import image from "../../../../assets/images/heli image .jpg";
import { Col, Row } from "antd";
import GradidentCircle from "../../../../components/GradidentCircle";
function AboutUs() {
  return (
    <div className="container relative">
      <div
        className="gradident absolute "
        style={{ top: 0, right: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <div
        className="gradident absolute "
        style={{ bottom: -100, left: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <div className="section-heading my-20">
        <h1 className="fw-bold display-5 text-center text-3xl md:text-5xl font-semibold">
          What makes us different?
        </h1>
      </div>
      <div className="flex gap-10 pb-20 items-center">
        <Row gutter={32} align="middle">
          {" "}
          {/* Add align="middle" to center align items */}
          <Col xs={{ span: 24 }} md={{ span: 12 }} className="about-section">
            <div className="about-summary pt-4 text-style">
              <p>
                <strong>Reliable Service:</strong> Experience Travel with
                Confidence. Our commitment to reliability ensures that you can
                travel with peace of mind. Trust in our dependable and
                trustworthy travel solutions, designed to make your journey
                seamless and worry-free.
              </p>
              <p className="mt-1">
                <strong>Exceptionally Secure:</strong>Your Peace of Mind, Our
                Priority. With our round-the-clock customer care, enjoy
                exceptional support throughout every step of your journey. Our
                24/7 customer care team is dedicated to ensuring your travel
                experience is secure, smooth, and tailored to your needs.
              </p>
              <p className="mt-1">
                <strong>Competitive Prices:</strong>Affordable Excellence.
                Unlock the Best Deals with Us. We understand the importance of
                cost-effective travel. Benefit from our guaranteed competitive
                prices, allowing you to find the best deals and enjoy affordable
                flights without compromising on quality or service
              </p>

              {/* <p>
                <a className="primary-color">Read more ... </a>
              </p> */}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            className="about-section mt-10 md:mt-0"
          >
            <div className="about-featured-image flex w-full justify-center md:justify-end w-full h-[350px] md:h-[440px]">
              {/* <img
                src={image}
                className="h-full w-full md:w-[80%] w-[75%] 2xl:w-[60%] object-cover"
                alt="Featured"
              />{" "} */}
              <video
                width="320"
                height="240"
                loop
                autoPlay
                muted
                className="w-full h-full object-cover rounded-xl !border-transparent "
                title="Introduction video of Stainless steel equipments manufacturer for commercial kitchen, dairy, and pharmaceutical Nepal."
              >
                <source src="/video/helovideo-2.mp4" type="video/mp4" />
                <source src="/video/helovideo-2.mp4" type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AboutUs;
