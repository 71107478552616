import React from "react";
import Layout from "../../../layout";
import { Col, Divider, Row } from "antd";
import IncludesExcludes from "./IncludesExcludes";
import IterinaryContent from "./IterinaryContent";
import DetailTravel from "./DetailTravel";
import ContactBox from "./ContactBox";
import IterinaryDetails from "./IterinaryDetails";
import GradidentCircle from "../../../components/GradidentCircle";
import { useParams } from "react-router-dom";
import useObjectBySlug from "../../../hooks/useObjectBySlug";
import { trvelAndTrek } from "../../../constants/travel";
function TravelDetails() {
  const params = useParams();

  const data = useObjectBySlug(trvelAndTrek, params?.id as string);

  return (
    <Layout>
      <div className="container relative">
        <div
          className="gradident absolute "
          style={{ top: 0, right: -350, zIndex: 4 }}
        >
          <GradidentCircle />
        </div>

        <div
          className="gradident absolute "
          style={{ bottom: -100, left: -350, zIndex: 4 }}
        >
          <GradidentCircle />
        </div>
        <Row gutter={[24, 24]} className="md:mt-10">
          <h2 className="font-bold text-3xl border-bottom px-[12px]">
            {data?.title}
          </h2>
          <Divider className="mt-0 mx-[12px]" />
        </Row>
        <Row gutter={24}>
          <Col xs={{ span: 24 }} lg={{ span: 14 }} className="">
            <img src={data?.image} className="trek-detail-image" />

            <div className="w-full flex items-center flex-wrap gap-5 py-4">
              <h6 className="font-semibold text-[14px] sm:text-[16px]">
                <i
                  className="mr-1 fa fa-clock primary-color"
                  aria-hidden="true"
                />
                {data?.days} days
              </h6>
              <h6 className="font-semibold text-[14px] sm:text-[16px]">
                <i
                  className="mr-1 fa fa-calendar primary-color"
                  aria-hidden="true"
                />
                Available
              </h6>
              <h6 className="font-semibold text-[14px] sm:text-[16px]">
                <i
                  className="mr-1 fa fa-child primary-color"
                  aria-hidden="true"
                />
                Min Age : {data?.minAge}+
              </h6>
              <h6 className="font-semibold text-[14px] sm:text-[16px]">
                <i
                  className="mr-1 fa fa-user primary-color"
                  aria-hidden="true"
                />
                Min people : {data?.minPag}
              </h6>
            </div>
            <IncludesExcludes
              includes={data?.includes}
              includeTitle={data?.includesTitle}
            />
            <Divider />
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 10 }} className="">
            <IterinaryContent iterinary={data?.example} />
          </Col>
        </Row>
        <Row gutter={24} className="relative">
          <div
            className="gradident absolute "
            style={{ top: -300, left: -350, zIndex: 4 }}
          >
            <GradidentCircle />
          </div>
          <Col xs={{ span: 24 }} lg={{ span: 14 }} className="">
            <DetailTravel description={data?.description} />
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 10 }}>
            <ContactBox />
          </Col>
        </Row>
        <div className="relative">
          <div
            className="gradident absolute "
            style={{ top: 0, right: -350, zIndex: 4 }}
          >
            <GradidentCircle />
          </div>
          {/* <IterinaryDetails gallery={data?.gallery} /> */}
        </div>
      </div>
    </Layout>
  );
}

export default TravelDetails;
