import { useEffect, useState } from "react";

const useObjectBySlug = <T extends object>(
  dataArray: T[],
  slug: string | null
): T | null => {
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    // Find the object with the specified slug
    const foundObject = dataArray.find((item: any) => item?.slug === slug);

    // Update the state with the found object
    setData(foundObject || null);
  }, [dataArray, slug]);

  return data;
};

export default useObjectBySlug;
