import { Row, Col } from "antd";
import React, { useState } from "react";
import FlightCard from "./flightCard";
import { AllFlightList } from "../../../constants/flight";

function FlightInfo() {
  const [showAll, setShowAll] = useState(false);

  const renderItems = showAll ? AllFlightList : AllFlightList.slice(0, 6);
  return (
    <div className="container mt-20">
      <h2 className="font-semibold mb-8 text-3xl ">
        Our domestic flight destinations include:
      </h2>
      <Row gutter={[24, 24]}>
        {renderItems.map((flight) => {
          return <FlightCard key={flight.id} {...flight} />;
        })}
      </Row>
      <div
        className="text-sm text-sky-700 mt-3 text-center underline underline-offset-4 cursor-pointer"
        style={{
          color: "#2553b8",
        }}
        onClick={() => setShowAll((prev) => !prev)}
      >
        Show {showAll ? "less" : "more"}
      </div>
    </div>
  );
}

export default FlightInfo;
