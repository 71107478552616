import { Row, Col } from "antd";
import React from "react";
import HeliImage from "../../../../assets/images/heli image .jpg";
import Card from "./Card";
import GradidentCircle from "../../../../components/GradidentCircle";
import { homeServices } from "../../../../constants/home";
function Services() {
  return (
    <div className="container relative">
      <div
        className="gradident absolute "
        style={{ top: -200, right: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <div
        className="gradident absolute "
        style={{ top: 200, left: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <div className="section-heading my-20">
        <h1 className="fw-bold display-5 text-center text-3xl md:text-5xl font-semibold">
          Our Services
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-10 w-full mt-10">
        {homeServices?.map((service: any) => {
          return <Card key={service?.id} {...service} />;
        })}
      </div>
    </div>
  );
}

export default Services;
