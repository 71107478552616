import React from "react";
import Layout from "../../layout";
import aboutImage from "../../assets/images/aboutImage.png";
import AboutUsSection from "./AboutSection";
import Team from "./Team";
function About() {
  return (
    <Layout>
      <div className="about-image w-full ">
        <img
          src={aboutImage}
          alt=""
          className="h-96 w-full object-cover object-center "
        />
      </div>
      <div className="container">
        <AboutUsSection />
        <Team />
      </div>
    </Layout>
  );
}
export default About;
