import { Divider } from "antd";
import React from "react";
import GradidentCircle from "../../../../components/GradidentCircle";

function HighLights({ highLights }: any) {
  console.log(highLights);
  return (
    <div className="container mt-10 relative">
      <div
        className="gradident absolute "
        style={{ bottom: -100, left: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <h3 className="font-bold mb-4 text-3xl">HighLights</h3>
      <ul>
        {Array.isArray(highLights) &&
          highLights.map((item, index) => {
            return (
              <li className="list-disc ml-10 mb-4 text-[#555]" key={index}>
                <p>{item}</p>
              </li>
            );
          })}
      </ul>
      <Divider />
    </div>
  );
}

export default HighLights;
