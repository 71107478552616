import { Divider } from "antd";
import React from "react";
import GradidentCircle from "../../../../components/GradidentCircle";

function Description({ description }: any) {
  return (
    <div className="container mt-14 relative">
      <div
        className="gradident absolute "
        style={{ top: 100, right: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <h3 className="font-bold mb-6 text-3xl">Description</h3>
      <p>{description}</p>
      <Divider />
    </div>
  );
}

export default Description;
