import { Col } from "antd";
import React, { useContext } from "react";
import BookingContext from "../../../state";

function FlightCard({ ...flight }) {
  const { toggleBooleanValue } = useContext(BookingContext)!;

  return (
    <Col xs={24} md={12}>
      <div
        className="flex items-center justify-center gap-5 px-4 py-4 bg-[#f7f7f7] hover:bg-[#e7e7e7] cursor-pointer rounded"
        onClick={() => toggleBooleanValue("show")}
      >
        <i className="text-primary fa fa-plane text-3xl" />
        <div>
          <p className="text-lg font-bold">
            {flight.from} - {flight.to}
          </p>
          <p className=" text-sm">{flight.des}</p>
        </div>
      </div>
    </Col>
  );
}

export default FlightCard;
