import React, { useState } from "react";
import person from "../../assets/images/person.jpg";
import clsx from "clsx";
import teamMember from "../../constants/team";
import { Modal } from "antd";
interface IMember {
  name: string;
  position: string;
  description: string;
  image: any;
  reverse?: boolean;
  setModelData: any;
}

const dis = `It is a long established fact that a reader will be distracted by the
readable content of a page when looking at its layout. The point of
using Lorem Ipsum is that it has a more-or-less normal distribution of
letters, as opposed to using 'Content here, content here'`;
const Member = ({
  name,
  position,
  description,
  image,
  reverse,
  setModelData,
}: IMember) => {
  return (
    <section className="container our-Legacy flex flex-col gap-7 lg:gap-14 lg:max-w-max  lg:mt-10 lg:flex-row px-0 items-start  mb-10">
      <div className="lg:hidden order-first">
        <h1 className="text-lg font-semibold pt-5">{name}</h1>
        <h1 className="text-sm px-1 pb-2 lg:pb-5 text-primary">{position}</h1>
      </div>

      <div
        className={clsx("lg:w-1/2", reverse ? "lg:order-last" : "order-first")}
      >
        <div className="hidden lg:block">
          <h1 className="text-lg font-semibold pt-5">{name}</h1>
          <h1 className="text-sm px-1 lg:pb-5 text-primary">{position}</h1>
        </div>
        <p
          className="font-light text-style"
          style={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 10,
          }}
        >
          {description}
        </p>
        <span
          className="text-sm text-sky-700 underline underline-offset-4 cursor-pointer"
          style={{
            color: "#2553b8",
          }}
          onClick={() => setModelData()}
        >
          Read More
        </span>
      </div>

      <div
        className={clsx(
          "lg:w-1/2 overflow-hidden",
          reverse ? "lg:order-first" : "order-last"
        )}
      >
        <img
          src={image}
          alt=""
          className="w-full h-96 object-cover hover:scale-110 transaction"
        />
      </div>
    </section>
  );
};

function Team() {
  const [ModelMember, setModelMember] = useState<any>(null);
  return (
    <div>
      <h1 className="text-primary  font-semibold  text-3xl font-playfair text-center">
        Our Team
      </h1>
      {teamMember.map((member, index) => {
        return (
          <Member
            key={index}
            name={member?.name}
            position={member.position}
            description={member.description}
            image={member.image}
            reverse={member.reverse}
            setModelData={() => setModelMember({ ...member })}
          />
        );
      })}
      <Modal
        open={!!ModelMember}
        footer={null}
        centered
        onCancel={() => setModelMember(null)}
      >
        <div className="max-w-md mx-auto bg-white rounded-md overflow-hidden ">
          <div className="p-4">
            <h2 className="text-2xl font-semibold mb-2">{ModelMember?.name}</h2>
            <p className="text-gray-600 text-sm mb-4">
              {ModelMember?.position}
            </p>
            <p className="text-gray-800">
              {ModelMember?.description}
              {ModelMember?.detailDescription}
              {ModelMember?.thirdPara}
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Team;
