import React from "react";
import GradidentCircle from "../../../../components/GradidentCircle";
import image from "../../../../assets/images/sagarmatha-heli-tour.webp";

function Gallery({ gallery }: any) {
  const hasGallery = Array.isArray(gallery) && gallery.length > 0;

  return (
    <div className="container mt-10 relative">
      <div
        className="gradident absolute "
        style={{ bottom: -100, left: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      {hasGallery && (
        <h3 className="font-bold mb-10 text-3xl md:text-center">Gallery</h3>
      )}
      <div className="image-gallery mb-10">
        {hasGallery &&
          gallery.map((item, index) => (
            <div className="image-column" key={index}>
              <img src={item} alt={`Gallery ${index + 1}`} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default Gallery;
