import React from "react";
import Layout from "../../layout";

import Banner from "./Components/Banner";
import Services from "./Components/Service";
import TravelAndTour from "./Components/TravelAndTour";
import BookingForm from "./Components/BookingForm";
import AboutUs from "./Components/AboutUs";
import Client from "./Components/Client";
import Cta from "./Components/Cta";
import Blogs from "./Components/Blogs";
import Video from "./Components/Video";

function Home() {
  return (
    <Layout>
      <Banner />
      <Services />
      <Video />
      <TravelAndTour />
      <BookingForm />
      <AboutUs />
      {/* <Client /> */}
      <Cta />
      <Blogs />
    </Layout>
  );
}

export default Home;
