import React from "react";
import Layout from "../../../layout";
import { Row, Col } from "antd";
import GradidentCircle from "../../../components/GradidentCircle";
import BlogCard from "../../Home/Components/Blogs/BlogCard";
import { blogs } from "../../../constants/home";
import { useParams } from "react-router-dom";
import useObjectBySlug from "../../../hooks/useObjectBySlug";
import { BlogsData } from "../../../constants/blogs";
function BlogsDetails() {
  const params = useParams();

  const data = useObjectBySlug(BlogsData, params?.id as string);

  return (
    <Layout>
      <div className="container relative">
        <div
          className="gradident absolute "
          style={{ top: 0, right: -350, zIndex: 4 }}
        >
          <GradidentCircle />
        </div>
        <div
          className="gradident absolute "
          style={{ bottom: -100, right: -350, zIndex: 4 }}
        >
          <GradidentCircle />
        </div>
        <Row gutter={[20, 20]} className="md:mt-10">
          <Col xs={24} lg={15}>
            <Row className=" overflow-hidden rounded-xl">
              <img
                className=" lg:min-h-[220px]  object-cover transition duration-500 hover:scale-110 w-full"
                src={data?.image}
              />
            </Row>
            <Row gutter={[20, 20]} className="mt-5">
              <Col
                sm={1}
                className="mt-14 hidden sm:flex flex flex-col gap-4 items-center  w-full "
              >
                {/* <i className=" fa-brands fa-facebook text-2xl cursor-pointer" />
                <i className=" fa-brands fa-instagram text-2xl cursor-pointer" />
                <i className=" fa-brands fa-twitter text-2xl cursor-pointer" /> */}
              </Col>
              <Col xs={24} sm={22} className="relative">
                <div
                  className="gradident absolute "
                  style={{ top: 0, left: -350, zIndex: 4 }}
                >
                  <GradidentCircle />
                </div>
                <div className="flex flex-col items-start md:flex-row md:items-center gap-4">
                  <div className="flex gap-3">
                    {data?.tags.map((tag, index) => {
                      return (
                        <span
                          className="text-primary bg-secondary border border-primary rounded-full px-4 sm:px-7 py-2"
                          key={index}
                        >
                          {tag}
                        </span>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <h3 className="text-xl sm:text-2xl md:text-3xl mt-2.5 font-bold text-[#1c1b1b] my-3 sm:mb-0 ">
                    {data?.title}
                  </h3>

                  <div className="my-6 text-base">
                    {data?.description.map((des) => {
                      return (
                        <div key={des?.id} className="mb-3">
                          <h3 className="mb-1 font-semibold">{des?.title}</h3>
                          <p>{des?.description}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={9}>
            <div className="hidden lg:flex flex-col w-full pb-5">
              <h1 className="pl-5 font-semibold text-2xl ">Similar Blogs</h1>
              {BlogsData.filter((item) => item.slug !== params?.id)
                .slice(0, 3)
                ?.map((blog) => {
                  return <BlogCard key={blog.id} {...blog} />;
                })}
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default BlogsDetails;
