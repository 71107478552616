import { createContext, useState, ReactNode } from "react";

interface BookingContextProps {
  showForm: boolean;
  toggleBooleanValue: (setter: "show" | "hide") => void;
}

const BookingContext = createContext<BookingContextProps | undefined>(
  undefined
);

export const BookingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [showForm, setshowForm] = useState(false);

  const toggleBooleanValue = (setter: "show" | "hide") => {
    setter === "show" ? setshowForm(true) : setshowForm(false);
  };

  return (
    <BookingContext.Provider value={{ showForm, toggleBooleanValue }}>
      {children}
    </BookingContext.Provider>
  );
};

export default BookingContext;
