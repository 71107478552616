import React, { useContext } from "react";
import HeliImage from "../../../../assets/images/heli image .jpg";
import { useNavigate } from "react-router-dom";
import BookingContext from "../../../../state";

function ChopperCard({ ...service }) {
  const navigate = useNavigate();

  return (
    <div
      className="w-full h-full bg-secondary service-card rounded-xl overflow-hidden transition flex flex-col cursor-pointer"
      onClick={() => navigate(`/chopper/${service?.slug}`)}
    >
      <div className="card-body transition flex flex-col h-full">
        <div className="h-48 overflow-hidden transition">
          <img
            src={service?.image || HeliImage}
            className="object-cover h-full w-full object-center object-bottom service-card-image"
          />
        </div>
        <div className="text-section px-5 text-style flex-grow pb-4">
          <h6 className="text-center my-3 text-[#000] font-bold text-xl">
            {service?.title}
          </h6>
          <p
            className=""
            style={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 7,
            }}
          >
            {service?.intro}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ChopperCard;
