import React from "react";
import image from "../../assets/images/sil6.png";
import { useNavigate } from "react-router-dom";
import contacts from "../../constants/contacts";
function Footer() {
  const date = new Date().getFullYear();
  const navigate = useNavigate();
  return (
    <footer
      className="pt-3"
      style={{
        color: "#fff",
        background: `#222 url(${image}) center center no-repeat`,
      }}
    >
      <div className="container mx-auto sm:px-4 py-5">
        <div className="flex flex-wrap  pt-4">
          <div className="sm:w-1/5 pr-4 pl-4">
            <h5 className="mb-4">Aero Trust Travels and Tours</h5>
            <p>
              Embark on Confidence, Soar with Trust – Aero Trust Travels and
              Tours
            </p>
           
          </div>
          <div className="mt-10 sm:mt-0 sm:w-1/5 pr-4 pl-4">
            <h5 className="mb-4">Travel in Nepal</h5>
            <ul className="list-unstyled quick-links">
              <li onClick={() => navigate("/travel/A-Day-Hikein-Kathmandu")}>
                <a href="">Hiking package</a>
              </li>
              <li onClick={() => navigate("/travel/Jungle-Safari-In-Nepal")}>
                <a href="">Jungle Safari</a>
              </li>
              <li
                onClick={() =>
                  navigate("/travel/Adventure-Sports-Tour-in-Nepal")
                }
              >
                <a href="">Adventure Sports</a>
              </li>
              <li
                onClick={() =>
                  navigate("travel/Kathmandu-Seven-World-Heritage")
                }
              >
                <a href="">World Heritage Tour</a>
              </li>
            </ul>
          </div>
          <div className="mt-10 sm:mt-0 sm:w-1/5 pr-4 pl-4">
            <h5 className="mb-4">Chopper Services</h5>
            <ul className="list-unstyled quick-links">
              <li
                onClick={() =>
                  navigate("/chopper/helicopter-search-and-rescue")
                }
              >
                <a href="">Search and Rescue</a>
              </li>
              <li
                onClick={() =>
                  navigate("/chopper/helicopter-charter-service-nepal")
                }
              >
                <a href="">Heli Charter Service</a>
              </li>
              <li onClick={() => navigate("/chopper/pokhara-Helicopter-tour")}>
                <a href="">Heli Tour</a>
              </li>
            </ul>
          </div>
          <div className="mt-10 sm:mt-0 sm:w-1/5 pr-4 pl-4">
            <h5 className="mb-4">Blogs</h5>
            <ul className="list-unstyled quick-links">
              <li onClick={() => navigate("/blogs/Aero-trek-tales")}>
                <a href="">Aero Trek Tales</a>
              </li>
              <li onClick={() => navigate("/blogs/festivals-in-nepal")}>
                <a href="">Festivals in Nepal</a>
              </li>
              <li onClick={() => navigate("/blogs/sherpa-chronicles")}>
                <a href="">Sherpa Chronicles</a>
              </li>
            </ul>
          </div>
          <div className="mt-10 sm:mt-0 sm:w-1/5 pr-4 pl-4">
            <h5 className="mb-4">Contact Information</h5>
            <ul className="list-unstyled quick-links">
              <li>
                <p className="">{contacts.address}</p>
              </li>
              <li>
                <a href="">{contacts.number}</a>
              </li>

              <li>
                <a href={`mailto:${contacts?.email}}`}>{contacts.email}</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-wrap ">
          <div className="sm:w-full pr-4 pl-4 flex items-center justify-between sm:w-full pr-4 pl-4 md:w-full pr-4 pl-4 mt-2 sm:mt-2 ">
            <p className="h6">© {date} All right Reserved.</p>
            <p
              className="h6 cursor-pointer"
              onClick={() => navigate("/terms-and-conditions")}
            >
              Terms and Conditions
            </p>
          </div>
          <hr />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
