import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BookingContext, { BookingProvider } from "../state";
import BookingFormComponent from "../components/BookingForm";

function Layout({
  className,
  children,
  ...props
}: React.ComponentPropsWithRef<"div">) {
  const { showForm, toggleBooleanValue } = useContext(BookingContext)!;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div
        className={clsx(
          "fixed h-screen w-full md:w-[65%] top-0 right-0 bg-[#055160] transition ease-in-out delay-150 text-light overflow-x-auto",
          showForm ? "translate-x-0" : "translate-x-full"
        )}
        style={{ zIndex: 99 }}
      >
        <i
          className="fa-solid fa-x text-light text-3xl p-5 cursor-pointer flex flex-end w-full"
          onClick={() => toggleBooleanValue("hide")}
        />
        <BookingFormComponent parentClass="!w-full p-5 pt-5 pb-10 " />
      </div>
      <div className={clsx("Layout", className)} {...props}>
        <header className="">
          <Navbar />
        </header>
        <main className="overflow-hidden" style={{ paddingTop: "120px" }}>
          {children}
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
