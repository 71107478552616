import { Divider } from "antd";
import React from "react";
import GradidentCircle from "../../../components/GradidentCircle";

function Description() {
  return (
    <div className="container mt-14 relative">
      <div
        className="gradident absolute "
        style={{ top: 100, right: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <h3 className="font-bold mb-6 text-3xl">Description</h3>
      <p>
        Nepal is well-connected by a robust network of domestic airlines,
        ensuring seamless travel across the country. Among the prominent
        carriers are Nepal Airlines, Buddha Air, and Yeti Airlines, alongside a
        host of smaller operators. These airlines collectively provide efficient
        and convenient air travel, linking major cities, towns, and notable
        points of interest across the picturesque landscapes of Nepal. Whether
        you're planning to explore vibrant urban centers or venture into the
        scenic beauty of Nepal's towns and attractions, the diverse options of
        domestic airlines make your journey fast and accessible. Embrace the
        convenience of these carriers and discover the richness of Nepal's
        cultural and natural treasures with ease.
      </p>
      <Divider />
    </div>
  );
}

export default Description;
