import helicopter from "../assets/images/banner-heli-sightseeing.jpg";
import heliSky from "../assets/images/Home/chopper/heli-sky.jpg";
import heliMountain from "../assets/images/Home/chopper/heli-mountain2.jpg";
import heliMountainsecond from "../assets/images/Home/chopper/helo-mounmtain.jpg";
import heliMountainsnow from "../assets/images/Home/chopper/helo-snow.jpg";
import heliGrass from "../assets/images/Home/chopper/heli-grass.jpg";
import HeliCopter from "../assets/images/Home/helicopture.jpg";
export const chopperData = [
  {
    id: 1,
    title: "Search and Rescue",
    image: helicopter,
    slug: "helicopter-search-and-rescue",
    intro:
      "Step into the realm of unmatched speed and efficiency with Aero Trust's Search and Rescue Helicopter Service, where a distinguished 12-year history converges to offer swift, breathtaking solutions, elevating rescue missions to unprecedented heights.",
    description:
      "Experience the unmatched speed and efficiency of Aero Trust's Search and Rescue Helicopter Service. With a distinguished 12-year history in the industry, Aero Trust emerges as your steadfast ally in elevating rescue missions. Our helicopters, meticulously designed for optimal safety and performance, stand ready to respond swiftly to emergencies, offering a faster, breathtaking, and efficient approach to critical locations. Aero Trust serves as the beacon of hope, prepared to elevate your rescue missions to unprecedented heights.",
    overview: [
      {
        id: 1,
        title: "Elevate Your Mission",
        des: "Immerse in adrenaline with Aero Trust's cutting-edge helicopters.Elevate rescue missions with swift and dramatic responses to critical situations.",
      },
      {
        id: 2,
        title: "Swift Response",
        des: "Time is crucial: Aero Trust ensures lightning-fast responses to emergencies.Our helicopters serve as immediate wings of assistance in critical situations.",
      },
      {
        id: 3,
        title: "Precision and Capacity",
        des: "Precision-designed helicopters by Aero Trust accommodate up to five passengers, ensuring safety and efficiency with a 450 KGS weight capacity.",
      },
      {
        id: 4,
        title: "Aerial Mastery",
        des: "Expert pilots from Aero Trust navigate challenging terrains, mastering the skies with unparalleled expertise.",
      },
      //   {
      //     id: 5,
      //     title: "Weather-Proof Operations",
      //     des: "Aero Trust remains resilient in unpredictable weather, equipping helicopters to handle adverse conditions.",
      //   },
    ],
    highLights: [
      "Elevate rescue missions with swift and dramatic responses to critical situations.",
      "Our helicopters serve as immediate wings of assistance in critical situations.",
      "In demanding situations, our helicopters stand as beacons of hope, overcoming obstacles with precision.",
      "Your rescue mission progresses unhindered with our helicopters, designed to withstand unpredictable weather challenges.",
    ],
    gallery: [helicopter, heliSky, helicopter],
    includes: [],
    excludes: [],
  },
  {
    id: 5,
    title: "Helicopter Charter Service",
    image: HeliCopter,
    slug: "helicopter-charter-service-nepal",
    intro:
      "Escape monotony and elevate your travel experience with our Helicopter Charter Service, providing convenient and cost-effective pickups from various destinations, ensuring a comfortable and memorable journey across diverse landscapes.",
    description: `Embark on a journey of unrivaled luxury with Aero Trust's Helicopter Charter Service, seamlessly merging efficiency with opulence. Whether you're navigating scenic landscapes, venturing to remote destinations, or opting for a sophisticated alternative to traditional travel, our service ensures a swift, comfortable, and unforgettable experience. From the pristine shores to towering mountain ranges, our reach extends across diverse terrains, liberating your adventure from the constraints of conventional transportation.
    Let Aero Trust and Travel redefine your exploration, where each flight becomes a unique chapter in your travel narrative. Our team is dedicated to curating an experience that aligns with your distinct preferences, transforming your journey into an extraordinary adventure. Trust us to elevate your travel with convenience, comfort, and awe-inspiring views, offering a harmonious blend that transcends the ordinary and sets a new standard for exploration.`,
    overview: [
      {
        id: 1,
        title: "Skybound Luxury Journeys",
        des: "Immerse in travel opulence with our Helicopter Charter Service, offering a sophisticated alternative for a redefined journey across diverse landscapes.",
      },
      {
        id: 2,
        title: "Seamless Soaring Escapes",
        des: "Picture yourself above breathtaking landscapes. Our Helicopter Charter caters to discerning travelers, blending efficiency, luxury, and unforgettable moments seamlessly.",
      },
      {
        id: 3,
        title: "Effortless Adventures, Limitless Travel",
        des: "Liberate your adventure from transportation constraints. From pristine shores to mountain ranges, traverse diverse terrains effortlessly with our Helicopter Charter Service",
      },
      {
        id: 4,
        title: "Transformative Exploration: Aero Trust",
        des: "Step into a world where each flight is a chapter in your travel story. Trust us to curate experiences, transforming the way you explore with convenience, comfort, and awe-inspiring views.",
      },
    ],
    highLights: [],
    gallery: [heliGrass, helicopter, heliSky],
    includes: [],
    excludes: [],
  },
  {
    id: 2,
    title: "Pokhara Heli Tour",
    image: heliSky,
    slug: "pokhara-Helicopter-tour",
    intro:
      "Discover Nepal's beauty from new heights with Pokhara City Heli Services by Aero Trust Travel. Soar above majestic peaks and hidden gems for an unparalleled and thrilling adventure.",
    description: `Get ready for a thrilling and awe-inspiring experience as we take you on a helicopter journey like no other! The Pokhara Helicopter Tour is your ticket to exploring the vibrant city, nearby hills, and majestic mountains in the north. Brace yourself for an unforgettable ride that promises immense pleasure and a lifetime of memories.
      Soar to new heights and experience Nepal like never before with Pokhara City Heli Services, a premier helicopter tour and charter service brought to you by travel and trek. Elevate your journey and witness the breathtaking beauty of the Himalayan mountains, serene lakes, and natural wonders of the region from the comfort of our helicopters.
      Our services go beyond the ordinary, offering a spectrum of exhilarating experiences, including scenic flights, mountain flights, rescue operations, and charter flights. Whether you're an adventure seeker, nature enthusiast, or someone simply longing for a unique perspective, Pokhara City Heli Services is your ticket to explore Nepal from the skies.
      Discover the diverse Pokhara Heli packages tailored to suit your preferences, each promising a distinctive adventure based on the places you wish to explore. From the enchanting Pokhara Valley to the hidden gems of Kaphuche, Sikles, Dhampus, and Ghandruk, we bring you an opportunity to unveil the wonders of these locations like never before.
      From high above, witness the majestic peaks of the Annapurna range, including the iconic Machhapuchhre and Dhaulagiri. Traverse the skies and gaze upon serene lakes, lush forests, and terraced fields that paint a picturesque landscape below. Our helicopter tours offer a unique and immersive perspective, allowing you to absorb the beauty of the region in a way that words simply cannot capture.
      But the adventure doesn't end there! Hike on Treks provides not only an exploration opportunity but also a chance to book transportation for convenient travel within these areas. Seize the moment, let the wind carry you, and embark on a sky-high escapade that will leave you with memories to last a lifetime.
      Choose Pokhara City Heli Services for an unparalleled journey, where every moment in the air is a celebration of Nepal's stunning landscapes and natural wonders. Book your helicopter adventure today and take flight with Aero Trust Travel and Tour!`,
    overview: [
      {
        id: 1,
        title: "Pokhara Heights Adventure",
        des: "Embark on a thrilling helicopter journey with Pokhara City Heli Services to explore Nepal's vibrant city, majestic mountains, and serene lakes.",
      },
      {
        id: 2,
        title: "Skyline Wonders Expedition",
        des: "Elevate your experience with Aero Trust's premier helicopter tours, offering exhilarating flights over the Himalayan mountains and hidden gems like Kaphuche and Ghandruk.",
      },
      {
        id: 3,
        title: "Annapurna Panorama Tour",
        des: "Witness the breathtaking beauty of Annapurna peaks, serene lakes, and lush landscapes on a unique and immersive helicopter tour with Pokhara City Heli Services.",
      },
      {
        id: 4,
        title: "Beyond Skies and Trails",
        des: "Choose Pokhara City Heli Services for an unparalleled journey that goes beyond the ordinary, celebrating Nepal's stunning landscapes and natural wonders from the air.",
      },
    ],
    highLights: [
      "Best views of Pokhara and the Himalayas",
      "Fast and efficient transportation in remote areas",
      "Versatile services for tours, flights, and rescue operations",
      "A popular and sought-after service in Nepal",
    ],
    gallery: [helicopter, heliSky, helicopter],
    includes: [
      "Breathtaking 30-minute helicopter flight over Pokhara and the Himalayas.",
      "Experienced pilot and crew.",
      "Spectacular views of Annapurna range, Machhapuchhre, and Dhaulagiri",
      "Convenience of booking through Hike on Treks.",
    ],
    excludes: ["Personal expenses.", "Additional services not mentioned."],
  },
  {
    id: 3,
    title: "Everest to Lukla Heli",
    image: heliMountain,
    slug: "Everest-to-lukla-helicopter-service",
    intro:
      "Are you seeking an alternative to walking the same route back from Everest Base Camp to Lukla after completing the Everest Base Camp trek",
    description: `We have the perfect solution for you! We offer a convenient helicopter pickup service from Everest Base Camp to Lukla or Kathmandu at a reasonable cost. Our helicopter pickup service is not only punctual but also cost-effective, providing you with a swift and comfortable return journey.
    The Everest Base Camp trek is a dream holiday package, offering a unique opportunity to witness Mount Everest up close and immerse yourself in the authentic environment of the world's highest mountain area. While the trek is a fantastic experience, traversing hilly terrain and retracing the same route during the return journey may become monotonous.
    For those who wish to break free from the repetitive trekking route and savor the majestic Himalayan views from a unique perspective, our helicopter flight from Everest Base Camp to Lukla is the optimal choice. Enjoy the breathtaking scenery and make your return journey truly memorable.
    `,
    overview: [
      {
        id: 1,
        title: "Everest Return Elevated",
        des: "Break away from the ordinary return journey from Everest Base Camp with our convenient and cost-effective helicopter pickup service to Lukla or Kathmandu.",
      },
      {
        id: 2,
        title: "Skybound Everest Trek",
        des: "Elevate your Everest Base Camp trek with our punctual and comfortable helicopter pickup, providing a swift return and a unique perspective of the majestic Himalayan views.",
      },
      {
        id: 3,
        title: "Beyond Base Camp Trek",
        des: "Explore Mount Everest up close on the dream holiday package of the Everest Base Camp trek, and opt for our helicopter pickup for a memorable and scenic return journey.",
      },
      {
        id: 4,
        title: "Monotony-Free Return",
        des: "Transform your trekking experience by choosing our helicopter flight from Everest Base Camp to Lukla, escaping the monotony and embracing breathtaking Himalayan views.",
      },
    ],
    highLights: [
      "Swift and Comfortable: Save time and effort with a quick helicopter ride instead of a long trek.",
      "Stunning Views: Experience the awe-inspiring Himalayan landscape from the sky.",
      "Convenient Return: Reach Lukla or Kathmandu conveniently, avoiding the monotony of retracing your steps.",
    ],
    gallery: [heliMountain, heliMountainsecond, heliMountainsnow],
    includes: [],
    excludes: [],
  },

  {
    id: 4,
    title: "Rara Lake Tour",
    image: heliGrass,
    slug: "Rara-lake-Tours-Nepal",
    intro:
      "Brace yourself for the adventure of a lifetime with our Rara Lake Helicopter tour—an ultimate package designed for thrill-seekers and nature enthusiasts alike.",
    description: `Embark on an extraordinary journey to unveil the enchanting beauty of Nepal's largest gem, Rara Lake, nestled in the remote Mugu district. Rara Lake sits at an elevation of 2990 m, offering a mirror-like reflection of its stunning surroundings. Encompassing an area of 10.8 sq. km, this pristine lake is cradled by verdant hills, creating a picturesque setting within the national park. During a visit to Rara Lake, one is greeted with a kaleidoscope of colors, as the lake mysteriously changes its hues throughout the day. The bluest water in the world is showcased here, and the lake's background transforms continuously, adding to its enigmatic charm. Boating on the crystal-clear waters provides a unique experience, where the hills appear both above and below, creating a mesmerizing mirror effect.
    Rara Lake is not just a destination; it's a source of meaning and introspection. At this high altitude, the air resonates with a soothing melody, and the pureness of the air prompts a realization of the act of breathing itself. As tiredness sets in, gazing into the lake unveils three endemic fish species, symbolizing the joyous essence of life.
    As the largest lake in Nepal, spanning 10.8 sq. km and reaching a depth of 167 m, Rara Lake is a magnificent waterbody surrounded by lush green hills. The lake feeds the Mugu Karnali River, adding to its grandeur. What sets Rara Lake apart is its pristine and less-crowded environment, with minimal human impact. Amenities such as hotels, boats, and an airport serve solely to enhance your journey, ensuring an untouched natural experience.    
    `,
    overview: [
      {
        id: 1,
        title: "Heaven on Earth Unveiled!",
        des: "Rara Lake, a mythical haven, invites you to connect with your inner soul amidst its deep blue waters mirroring the dark sky, radiant sunrays, and the symphony of nature's music—a destination beyond compare.",
      },
      {
        id: 2,
        title: "Wonders of Rara Lake",
        des: "Rara Lake, Nepal's largest at 3,000 meters in the western region, nestled within Rara National Park, offers a remote paradise—an unparalleled sanctuary for nature lovers, a distant dream from Kathmandu.",
      },
      {
        id: 3,
        title: "Why Rara Helicopter Tour",
        des: "Escape ordinary bounds with the Rara Lake Helicopter Tour, unlocking easy access to this remote haven. Immerse in unspoiled beauty, where every moment is a masterpiece—a transcendent adventure awaits!",
      },
    ],
    highLights: [
      "Discover the largest lake in Nepal, Rara Lake, ensconced in the breathtaking Rara National Park.",
      "Witness the untouched beauty of this remote paradise that remains a dream destination for all.",
      "Experience the convenience and safety of a helicopter ride, making the journey accessible in a single day.",
    ],
    gallery: [heliGrass, helicopter, heliSky],
    includes: [
      "Private chartered helicopter tour",
      "Sightseeing as per the itinerary",
      "All government taxes",
      "Passenger insurance",
    ],
    excludes: [
      "National park entry permit (Nrs. 3000)",
      "Airport Tax (Nrs. 200)",
      "Any meals or personal nature expenses",
    ],
  },
];
