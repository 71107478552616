import { ErrorMessage } from "@hookform/error-message";
import { Alert } from "antd";
import clsx from "clsx";
import React from "react";
interface ATType {
  name: string;
  register: any;
  error: any;
  inputClass?: string;
  labelName?: string;
}

function ATInput({ name, register, error, inputClass, labelName }: ATType) {
  return (
    <div>
      {" "}
      <label className={clsx("text-lg font-bold capitalize", labelName)}>
        {name}
      </label>
      <input
        type={"text"}
        className={clsx(
          "border border-secondary outline-none text-dark  block w-full rounded-md  p-3 mt-2 ",
          inputClass
        )}
        id={name}
        {...register(`${name}`)}
      />
      <ErrorMessage
        errors={error}
        name={name}
        render={({ message }) => (
          <Alert
            message={message}
            type="error"
            className={clsx("mt-2 text-center ")}
          />
        )}
      />
    </div>
  );
}

export default ATInput;
