import React from "react";
import TravelCard from "./TravelCard";
import GradidentCircle from "../../../../components/GradidentCircle";
import { trvelAndTrek } from "../../../../constants/travel";

function TravelAndTour() {
  return (
    <div className="container relative">
      <div
        className="gradident absolute "
        style={{ top: 0, right: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <div
        className="gradident absolute "
        style={{ bottom: -100, left: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <div className="section-heading mt-20 mb-5">
        <h1 className="fw-bold display-5 text-center text-3xl md:text-5xl font-semibold">
          Travel and Tour
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  w-full">
        {trvelAndTrek?.slice(0, 3)?.map((travel) => {
          return <TravelCard key={travel?.id} {...travel} />;
        })}
      </div>
    </div>
  );
}

export default TravelAndTour;
