import React from "react";
import Layout from "../../layout";
import GradidentCircle from "../../components/GradidentCircle";
import LetsTalk from "./LetsTalk";
import ContactUsForm from "./ContactUsForm";
import Map from "./Map";
function Contact() {
  return (
    <Layout>
      <div className="container">
        <div className=" flex flex-col  md:flex-row  justify-between items-start  md:px-0 md:gap-22  md:my-10 relative">
          <div
            className="gradident absolute "
            style={{ top: -249, right: -350, zIndex: 2 }}
          >
            <GradidentCircle />
          </div>
          <div
            className="gradident absolute "
            style={{ bottom: -249, left: -350, zIndex: 2 }}
          >
            <GradidentCircle />
          </div>

          <div className="order-last md:order-first px-2 md:px-0 w-1/2">
            <LetsTalk />
          </div>
          <div
            className={
              " bg-white p-6 md:p-14  border w-full border-0 md:w-2/3 "
            }
            style={{ boxShadow: "0px 0px 43px rgba(0, 0, 0, 0.151)" }}
          >
            <ContactUsForm />
          </div>
        </div>
        <Map />
      </div>
    </Layout>
  );
}

export default Contact;
