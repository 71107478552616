import React from "react";

function IncludesExcludes({ includes, includeTitle }: any) {
  return (
    <div>
      <h3 className="font-bold mb-4 text-2xl">{includeTitle}</h3>
      <div>
        {includes?.map((item: any) => {
          return (
            <p className="flex gap-3 items-baseline text-lg " key={item?.id}>
              <i className="fa-solid fa-circle-check text-[#2d8020] ml-3 mb-2 text-[#2d8020] " />
              {item}
            </p>
          );
        })}
      </div>
    </div>
  );
}

export default IncludesExcludes;
