import React, { useContext } from "react";
import { Col, Row } from "antd";
import BookingContext from "../../../state";
function DestinationCard({ ...flight }) {
  const { toggleBooleanValue } = useContext(BookingContext)!;

  return (
    <Col xs={12}>
      <div
        className="destination-list flex items-center justify-center px-4 py-4 bg-[#f7f7f7] cursor-pointer rounded font-medium"
        onClick={() => toggleBooleanValue("show")}
      >
        {flight.from} - {flight.to}
      </div>
    </Col>
  );
}

export default DestinationCard;
