import React from "react";
import BlogCard from "./BlogCard";
import GradidentCircle from "../../../../components/GradidentCircle";
import { blogs } from "../../../../constants/home";
import { BlogsData } from "../../../../constants/blogs";
function Blogs() {
  return (
    <div className="container relative pb-20">
      <div
        className="gradident absolute "
        style={{ top: 0, right: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <div
        className="gradident absolute "
        style={{ bottom: 0, left: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <div className="section-heading mt-20 mb-5">
        <h1 className="fw-bold display-5 text-center text-3xl md:text-5xl font-semibold">
          Blogs
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  w-full mt-10">
        {BlogsData.slice(0, 3)?.map((blog) => {
          return <BlogCard key={blog.id} {...blog} />;
        })}
      </div>
    </div>
  );
}

export default Blogs;
