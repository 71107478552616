import { Controller, Control } from "react-hook-form";
import { Input, Alert, InputProps } from "antd";
import { ErrorMessage } from "@hookform/error-message";
import clsx from "clsx";
import { isTemplateExpression } from "typescript";

type SelectTypes = InputProps & {
  name: string;
  type?: string;
  label?: React.ReactNode;
  error?: any;
  parentClass?: string;
  labelClass?: string;
  inputClass?: string;
  optionArray?: any;
  placeholder?: string;
  required?: boolean;
  rules?: any;
  value?: any;
  register: any;
};

const Select = ({
  name,
  label,
  error,
  value,
  register,
  parentClass,
  inputClass,
  labelClass,
  optionArray,
  placeholder,
  type,
  required = false,
  showCount,
  rules = null,
  ...rest
}: SelectTypes) => {
  return (
    <div className="mb-4">
      <label className="text-lg font-bold capitalize">{name}</label>

      {required && "*"}

      <select
        className={clsx(
          "border border-secondary outline-none text-dark  block w-full  rounded-md  p-3 mt-2",
          inputClass
        )}
        id={`${name}`}
        style={{
          border: "1px solid rgba(82, 76, 76, 0.38)",
        }}
        placeholder={placeholder}
        {...register(`${name}`)}
        {...rest}
      >
        <option value="">Category</option>
        {optionArray?.map((item: any, index: any) => {
          return (
            <option key={index} value={`${item}`} className="py-6 mt-2">
              {item}
            </option>
          );
        })}
      </select>
      <ErrorMessage
        errors={error}
        name={name}
        render={({ message }) => (
          <Alert message={message} type="error" className="mt-2 text-center " />
        )}
      />
    </div>
  );
};

export default Select;
