import React from "react";
import contacts from "../../constants/contacts";

function LetsTalk() {
  return (
    <div className=" mt-16 md:mt-0">
      <div className="hidden md:block">
        <h1 className="text-primary font-bold  text-4xl pb-5">Let's talk!</h1>
        <p className="w-2/3 font-inter text-[#042E33] text-xl sm:text-xl text-style">
          Share some details about your travel preferences and plans, and our
          team will be in touch with you shortly to assist in creating your
          perfect journey.
        </p>
        <br />
        <div className="hidden md:flex items-center my-3">
          <div className="w-2/6 text-center border-b leading-5 mr-2 text-secondary"></div>
          <h2 className="px-5 font-medium text-secondary text-xl">Or</h2>
          <div className="w-2/6 text-center border-b leading-5 mr-2 text-secondary"></div>
        </div>
      </div>
      <div className="lets-call block">
        <h1 className="text-brp-primary font-semibold opacity-60 mt-1 text-3xl ">
          Contact Us Via:
        </h1>
        <div className="contact mt-5">
          <div className="flex items-start gap-5 py-3">
            <i className="fa-solid fa-phone text-[25px] text-primary mt-1"></i>
            <div>
              <h1 className="font-bold opacity-70">Phone Number</h1>
              <h1 className="text-style">{contacts.number}</h1>
            </div>
          </div>
          <div className="flex items-start gap-5 py-3">
            <i className="fa-solid fa-envelope text-[25px] text-primary mt-1"></i>
            <div>
              <h1 className="font-bold opacity-70">Email</h1>
              <a
                className="hover:text-primary text-style"
                href={`mailto:${contacts?.email}`}
              >
                {" "}
                <h1 className="text-style"> {contacts?.email}</h1>
              </a>
            </div>
          </div>
          <div className="flex items-start gap-5 py-3">
            <i className="fa-solid fa-briefcase text-[25px] text-primary mt-1"></i>
            <div>
              <h1 className="font-bold opacity-70">Office Location</h1>
              <h1 className="cursor-pointer hover:text-primary text-style  text-style">
                {" "}
                {contacts?.address}
              </h1>
            </div>
          </div>

          <div className="flex items-start gap-5 py-3">
            <i className="fa-solid fa-globe text-[23px] text-primary mt-1"></i>
            <div>
              <h1 className="font-bold opacity-70 ">Social Media</h1>
              <a
                target="_blank"
                href={contacts?.facebook}
                className="flex gap-1 items-center hover:text-primary text-style"
              >
                {" "}
                <i className="fa-brands fa-facebook px-2"></i>
                <h1 className=" hover:text-primary text-style">Facebook </h1>
              </a>
              {/* <a
                target="_blank"
                href=""
                className="flex gap-1 items-center hover:text-primary text-style"
              >
                {" "}
                <i className="fa-brands fa-square-instagram px-2"></i>
                <h1 className=" hover:text-primary text-style">Instagram</h1>
              </a>
              <a
                target="_blank"
                href=""
                className="flex gap-1 items-center hover:text-primary text-style"
              >
                {" "}
                <i className="fa-brands fa-youtube px-2"></i>
                <h1 className=" hover:text-primary text-style ">Youtube</h1>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LetsTalk;
