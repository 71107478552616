import WorkingImage from "../../assets/images/Home/Team/work.jpg";
export default function AboutUsSection() {
  return (
    <section className="container our-Legacy flex flex-col gap-7 lg:gap-14 lg:max-w-max  lg:mt-10 lg:flex-row px-0 items-start lg:items-center mb-10">
      <h1 className="lg:hidden order-first text-3xl text-primary pt-5  ">
        About Us
      </h1>
      <div className="order-last lg:w-1/2 ">
        <h1 className="hidden lg:block text-3xl text-primary font-semibold mb-5">
          About Us
        </h1>
        <p className="font-light text-style">
          Founded 12 years ago in the heart of Khumbu, Aero Trust Travel and
          Tours is a trailblazing aviation company, born from the vision of a
          resilient Sherpa. Our legacy is built on a steadfast commitment to
          reliability, professionalism, and safety. Beyond being a conventional
          flight service, we embody the spirit of the mountains, fostering a
          genuine connection to Nepal's grandeur.
        </p>
        <p className="font-light text-style mt-2">
          As storytellers of Nepal's beauty, we aim to promote tourism and
          showcase the allure of the Himalayas. Join us in the skies for an
          unparalleled flight experience, where excellence meets the horizon,
          and embark on a journey of trust and reliability amidst the heights of
          Himalayan adventure.
        </p>
      </div>
      <div className="lg:w-1/2 lg:order-last">
        <img
          src={WorkingImage}
          alt=""
          className=" w-full h-full object-center "
        />
      </div>
    </section>
  );
}
