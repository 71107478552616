import React from "react";
import Layout from "../../layout";

function TermsAndConditions() {
  return (
    <Layout>
      <div className="bg-gray-100 p-8">
        <div className="max-w-3xl mx-auto bg-white p-8 rounded shadow">
          <h1 className="text-3xl font-bold mb-6">Cancellation Policies</h1>
          <ul className="list-disc pl-8 mb-8">
            <li>
              A cancellation charge of 10% of the fare will be applied if the
              ticket is canceled more than 24 hours before the scheduled flight
              time.
            </li>
            <li>
              If the cancellation is made on the same day as the flight, a
              charge of 33.33% of the fare will be levied.
            </li>
            <li>
              Passengers will be deemed as 'No-Show' if they fail to Check-In
              within the specified reporting time. Aero Trust Travel and Tour
              will not be responsible for refunding tickets in cases where
              passengers fall into the “NO SHOW’’ category.
            </li>
          </ul>
          <h2 className="text-2xl font-bold mb-6">Fare Categories</h2>
          <ul className="list-disc pl-8 mb-8">
            <li>
              <strong>Nepali/Indian Fares:</strong>
              <ul className="list-disc pl-8 mb-8">
                <li> This fare applies to citizens of Nepal and India.</li>
                <li>
                  {" "}
                  Citizens with a valid document issued by the Nepal Government
                  can benefit from this fare.
                </li>
              </ul>
            </li>
            <li>
              <strong>Foreigner (USD) Fare:</strong>
              <ul className="list-disc pl-8 mb-8">
                <li>
                  {" "}
                  This fare is for citizens other than Nepalese and Indian
                  nationality.
                </li>
                <li>A lower fare category is not applicable to foreigners.</li>
                <li>
                  OCI (Overseas Citizens of India) holders must pay this fare.
                </li>
              </ul>
            </li>
            <li>
              <strong>Residential Fare</strong>
              <ul className="list-disc pl-8 mb-8">
                <li>
                  Equivalent to the normal 'Y' Class fare, this is applicable
                  for foreign passengers working in an international
                  organization or diplomatic mission and residing in Nepal for
                  at least six (6) months before the flight date.
                </li>
              </ul>
            </li>
            <li>
              <strong>Senior and Elderly Passengers</strong>
              <ul className="list-disc pl-8 mb-8">
                <li>
                  Nepalese passengers aged above 70 years and economically
                  underprivileged receive a 50% discount on the normal (Y) fare.
                </li>
                <li>
                  Tickets must be issued from any sales office of Buddha Air.
                </li>
                <li>
                  Citizenship or a passport along with a copy is required for
                  official records.
                </li>
                <li>
                  Two seats per day/sector are allocated based on FIRST COME,
                  FIRST SERVE.
                </li>
              </ul>
            </li>
            <li>
              <strong>Person with Disability:</strong>
              <ul className="list-disc pl-8 mb-8">
                <li>A 50% discount on the normal (Y) fare is provided.</li>
                <li>
                  Tickets must be issued from any sales office of Buddha Air.
                </li>
                <li>
                  A National Federation of the Disabled-Nepal (NFD-N) issued
                  card or similar from the local government authority is
                  required, along with a copy for official records.
                </li>
              </ul>
            </li>
            <li>
              <strong>Child Fare:</strong>
              <ul className="list-disc pl-8 mb-8">
                <li>
                  Applicable for passengers aged between 02 Years to (under) 12
                  Years of age.
                </li>
                <li>33% discount on the normal 'Y' fare is applicable.</li>
              </ul>
            </li>
            <li>
              <strong>Infant:</strong>
              <ul className="list-disc pl-8 mb-8">
                <li>
                  Applicable for passengers aged 7 days from birth to (under) 02
                  years.
                </li>
                <li>
                  Only 10% of the normal 'Y' fare will be charged, and the
                  ticket will be issued at the check-in counter.
                </li>
              </ul>
            </li>
            <li>
              <strong>Identity:</strong>
              <ul className="list-disc pl-8 mb-8">
                <li>
                  Any valid photo identity is MANDATORY for CHECK-IN at the
                  airport.
                </li>
              </ul>
            </li>
          </ul>
          {/* Add more fare categories as needed */}

          {/* Add more passenger categories as needed */}
          <h2 className="text-2xl font-bold mb-6">Fare Classes</h2>
          <ul className="list-disc pl-8 mb-8">
            <li>
              <strong>Normal ‘Y’ Fare:</strong>
              <ul className="list-disc pl-8">
                <li>
                  A cancellation charge of 10% of the fare is applied if the
                  ticket is canceled before 11 hours from the flight time.
                </li>
                <li>
                  A cancellation charge of 33.33% of the fare is applied if the
                  cancellation is done within 11 hours of flight time.
                </li>
                <li>
                  Cancellation is prohibited within two (2) hours of flight
                  time.
                </li>
                <li>
                  Rescheduling can be done before two hours of flight time.
                </li>
                <li>
                  Passengers will be treated as ‘No-Show’ if they do not
                  Check-In within the stated reporting time, and Buddha Air is
                  not liable to refund the ticket in this case.
                </li>
                <li>
                  Passenger Service Tax (Airport Tax) is refundable for No Show
                  tickets.
                </li>
                <li>Refunds are made from the point of issue.</li>
                <li>The baggage allowance is 25 kg.</li>
                <li>The fare class will be “Y” for the Normal fare ticket.</li>
              </ul>
            </li>
            <li>
              <strong>ALPHA ‘A’ Fare:</strong>
              <ul className="list-disc pl-8">
                <li>
                  A cancellation charge of 25% of the fare is applied if the
                  ticket is canceled before 11 hours from flight time.
                </li>
                <li>
                  A cancellation charge of 50% of the fare is applied if the
                  cancellation is done within 11 hours from flight time.
                </li>
                <li>
                  Cancellation is prohibited within 2 hours of flight time.
                </li>
                <li>
                  Rescheduling of any flight can be done before two hours of
                  flight time.
                </li>
                <li>
                  Passengers will be treated as ‘No-Show’ if they do not
                  Check-In within the stated reporting time, and the company is
                  not liable to refund the tickets in this case.
                </li>
                <li>
                  Passenger Service Charge (Airport Tax) is refundable for No
                  Show tickets.
                </li>
                <li>The baggage allowance is 20 kg.</li>
                <li>An open ticket cannot be issued in Apex fare.</li>
                <li>The fare class will be “A” for the Apex fare ticket.</li>
              </ul>
            </li>
            <li>
              <strong>Bravo ‘B’ Fare:</strong>
              <ul className="list-disc pl-8">
                <li>
                  Only the Fuel surcharge will be refunded if cancellation is
                  made before 2 hours from flight time.
                </li>
                <li>
                  Rescheduling can be done under the terms and conditions of
                  rescheduling charges.
                </li>
                <li>
                  Cancellation is prohibited within two (2) hours of flight
                  time.
                </li>
                <li>Open Tickets cannot be issued in Bravo fare.</li>
                <li>
                  Passengers will be treated as ‘No-Show’ if they do not
                  Check-In within the stated reporting time, and the company is
                  not liable to refund the tickets in this case.
                </li>
                <li>
                  “B” fare will be sold on a FIRST COME FIRST SERVE basis.
                </li>
                <li>
                  Passenger Service Charge (Airport Tax) is refundable for No
                  Show tickets.
                </li>
                <li>The baggage allowance is 20 kg.</li>
                <li>The fare class will be “B” for the Bravo Fare ticket.</li>
              </ul>
            </li>
            <li>
              <strong>Charlie “C”, Delta “D” And Echo “E” Fare:</strong>
              <ul className="list-disc pl-8">
                <li>These fares are non-refundable and non-transferable.</li>
                <li>
                  Rescheduling can be done under the terms and conditions of
                  rescheduling charges.
                </li>
                <li>
                  An open ticket cannot be issued in Charlie, Delta, and Echo
                  fare.
                </li>
                <li>
                  Passengers will be treated as ‘No-Show’ if they do not
                  Check-In within the specified reporting time.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
}

export default TermsAndConditions;
