import React, { useRef } from "react";
import helicopter from "../../../../assets/images/banner-heli-sightseeing.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselItem from "./CarouselItem";
import GradidentCircle from "../../../../components/GradidentCircle";
import { bannerItems } from "../../../../constants/home";
function Banner() {
  const sliderRef = useRef(null);

  function next() {
    // @ts-ignore
    sliderRef?.current?.slickNext();
  }
  function prev() {
    // @ts-ignore
    sliderRef?.current?.slickPrev();
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
    autoplay: true,
    autoplaySpeed: 3000,
    appendDots: (dots: any) => <ul>{dots}</ul>,
    customPaging: (i: any) => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    ),
  };

  return (
    <section className="relative">
      <div
        className="gradident absolute "
        style={{ top: 0, right: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <div
        className="gradident absolute "
        style={{ bottom: -100, left: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <Slider {...settings} ref={sliderRef}>
        {bannerItems.map((item) => {
          return <CarouselItem {...item} key={item.id} />;
        })}
      </Slider>
      {/* <div className="flex justify-center items-center gap-2 sm:gap-5 mt-[-10px]">
        <i
          className="fa-solid text-primary text-xs opacity-60 fa-circle cursor-pointer hover:opacity-90"
          onClick={prev}
        ></i>
        <i className="fa-solid text-primary text-xs fa-circle cursor-pointer hover:opacity-90"></i>
        <i
          className="fa-solid text-primary text-xs opacity-60 fa-circle cursor-pointer hover:opacity-90"
          onClick={next}
        ></i>
      </div> */}
    </section>
  );
}

export default Banner;
