import React from "react";
import Layout from "../../layout";
import BlogCard from "../Home/Components/Blogs/BlogCard";
import GradidentCircle from "../../components/GradidentCircle";

import Blogs from "../Home/Components/Blogs";
import { BlogsData } from "../../constants/blogs";
function BlogList() {
  return (
    <Layout>
      <div className="container mb-20 relative">
        <div
          className="gradident absolute "
          style={{ top: 0, right: -350, zIndex: 4 }}
        >
          <GradidentCircle />
        </div>
        <div
          className="gradident absolute "
          style={{ bottom: -100, left: -350, zIndex: 4 }}
        >
          <GradidentCircle />
        </div>
        <div className=" ml-5 md:mt-10 ">
          <h1 className="fw-bold display-5 text-left text-3xl md:text-5xl font-semibold">
            Blogs
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  w-full ">
          {BlogsData?.map((blog) => {
            return <BlogCard key={blog.id} {...blog} />;
          })}
        </div>
      </div>
    </Layout>
  );
}

export default BlogList;
