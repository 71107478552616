export const AllFlightList = [
  {
    id: 1,
    from: "Kathmandu",
    to: "Lukla",
    des: "Embark on an adventurous journey from Kathmandu to Lukla, the gateway to the Everest region.",
  },
  {
    id: 2,
    from: "Ramechap",
    to: "Lukla",
    des: "Experience the scenic flight from Ramechap to Lukla, setting the stage for your trekking expedition.",
  },
  {
    id: 3,
    from: "Kathmandu",
    to: "Pokhara",
    des: "Discover the enchanting beauty of Pokhara as you travel from Kathmandu to this lakeside city.",
  },
  {
    id: 4,
    from: "Kathmandu",
    to: "Phaplu",
    des: "Connect with the tranquility of Phaplu, starting your trek amidst the serene landscapes from Kathmandu.",
  },
  {
    id: 5,
    from: "Kathmandu",
    to: "Taplejung",
    des: "Embark on a captivating journey from Kathmandu to Taplejung, surrounded by the breathtaking hills of eastern Nepal.",
  },
  {
    id: 6,
    from: "Kathmandu",
    to: "Tumlingtar",
    des: "Begin your exploration from Kathmandu to Tumlingtar, a gateway to the diverse landscapes of eastern Nepal.",
  },
  {
    id: 7,
    from: "Kathmandu",
    to: "Janakpur",
    des: "Immerse yourself in the cultural richness of Janakpur as you travel from Kathmandu to this historic city.",
  },
  {
    id: 8,
    from: "Kathmandu",
    to: "Biratnagar",
    des: "Journey from Kathmandu to Biratnagar, experiencing the vibrant atmosphere of eastern Nepal.",
  },
  {
    id: 9,
    from: "Kathmandu",
    to: "Bhadrapur",
    des: "Connect with the easternmost town of Bhadrapur from Kathmandu, surrounded by lush greenery.",
  },
  {
    id: 13,
    from: "Kathmandu",
    to: "Bharapur",
    des: "Experience the charm of Bharatpur as you travel from Kathmandu to this bustling city in the heart of Nepal.",
  },
  {
    id: 10,
    from: "Kathmandu",
    to: "Simara",
    des: "Discover the simplicity of Simara on your journey from Kathmandu, surrounded by rural landscapes.",
  },
  {
    id: 11,
    from: "Kathmandu",
    to: "Bhairahawa",
    des: "Explore the cultural heritage of Bhairahawa as you travel from Kathmandu to this historic city.",
  },
  {
    id: 12,
    from: "Kathmandu",
    to: "Nepalgunj",
    des: "Uncover the treasures of Nepalgunj as you venture from Kathmandu to this vibrant western town.",
  },
  {
    id: 14,
    from: "Pokhara",
    to: "Jomsom",
    des: "Soar through the mountains on a flight from Pokhara to Jomsom, a gateway to the Mustang region.",
  },
  {
    id: 15,
    from: "Nepalgunj",
    to: "Surkhet",
    des: "Experience the blend of tradition and modernity as you travel from Nepalgunj to Surkhet.",
  },
  {
    id: 16,
    from: "Nepalgunj",
    to: "Sanfebagar",
    des: "Connect with the scenic beauty of Sanfebagar on your journey from Nepalgunj to this picturesque destination.",
  },
  {
    id: 17,
    from: "Nepalgunj",
    to: "Bajura",
    des: "Explore the remote landscapes of Bajura as you travel from Nepalgunj to this hidden gem.",
  },
  {
    id: 18,
    from: "Nepalgunj",
    to: "Simikot",
    des: "Embark on a unique journey from Nepalgunj to Simikot, experiencing the beauty of the Himalayan region.",
  },
  {
    id: 19,
    from: "Nepalgunj",
    to: "Rara",
    des: "Traverse the landscapes from Nepalgunj to Rara, discovering the pristine beauty of the largest lake in Nepal.",
  },
  {
    id: 20,
    from: "Nepalgunj",
    to: "Jumla",
    des: "Experience the remote charm of Jumla as you travel from Nepalgunj to this off-the-beaten-path destination.",
  },
  {
    id: 21,
    from: "Nepalgunj",
    to: "Dolpa",
    des: "Explore the wilderness of Dolpa on your journey from Nepalgunj to this secluded region.",
  },
  {
    id: 22,
    from: "Nepalgunj",
    to: "Rukum",
    des: "Discover the scenic beauty of Rukum as you travel from Nepalgunj to this lesser-known destination in western Nepal.",
  },
];

export const Trunk = [
  {
    id: 3,
    from: "Kathmandu",
    to: "Pokhara",
  },
  {
    id: 6,
    from: "Kathmandu",
    to: "Tumlingtar",
  },
  {
    id: 7,
    from: "Kathmandu",
    to: "Janakpur",
  },
  {
    id: 8,
    from: "Kathmandu",
    to: "Biratnagar",
  },
  {
    id: 9,
    from: "Kathmandu",
    to: "Bhadrapur",
  },
  {
    id: 13,
    from: "Kathmandu",
    to: "Bharapur",
  },
  {
    id: 10,
    from: "Kathmandu",
    to: "Simara",
  },
  {
    id: 11,
    from: "Kathmandu",
    to: "Bhairahawa",
  },
  {
    id: 12,
    from: "Kathmandu",
    to: "Nepalgunj",
  },
];

export const Slot = [
  {
    id: 1,
    from: "Kathmandu",
    to: "Lukla",
  },
  {
    id: 2,
    from: "Ramechap",
    to: "Lukla",
  },
  {
    id: 4,
    from: "Kathmandu",
    to: "Phaplu",
  },
  {
    id: 5,
    from: "Kathmandu",
    to: "Taplejung",
  },
  {
    id: 14,
    from: "Pokhara",
    to: "Jomsom",
  },
  {
    id: 15,
    from: "Nepalgunj",
    to: "Surkhet",
  },
  {
    id: 16,
    from: "Nepalgunj",
    to: "Sanfebagar",
  },
  {
    id: 17,
    from: "Nepalgunj",
    to: "Bajura",
  },
  {
    id: 18,
    from: "Nepalgunj",
    to: "Simikot",
  },
  {
    id: 19,
    from: "Nepalgunj",
    to: "Rara",
  },
  {
    id: 20,
    from: "Nepalgunj",
    to: "Jumla",
  },
  {
    id: 21,
    from: "Nepalgunj",
    to: "Dolpa",
  },
  {
    id: 22,
    from: "Nepalgunj",
    to: "Rukum",
  },
];
