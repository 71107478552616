import React from "react";
import contacts from "../../../../constants/contacts";

function BookingDetail() {
  return (
    <div className="flex flex-col md:w-[45%] 2xl:w-1/2 md:px-10">
      <h1 className="text-3xl font-semibold xl:text-4xl 2xl:text-5xl">
        Make a Booking
      </h1>
      <div className="mt-5 flex flex-col gap-4">
        <span>{contacts.email}</span>
        <span>{contacts.number}</span>
        <span>{contacts.address}</span>
      </div>
      <div className="flex gap-4 mt-4">
        <a href={`${contacts.facebook}`} target="_blank">
          <i className="fa-brands fa-facebook text-3xl" />
        </a>
        {/* <i className="fa-brands fa-whatsapp text-3xl" />
        <i className="fa-brands fa-instagram text-3xl" /> */}
      </div>
    </div>
  );
}

export default BookingDetail;
