import { Col, Row } from "antd";
import React from "react";
import GradidentCircle from "../../../../components/GradidentCircle";
function OverView({ overview }: any) {
  return (
    <div className="container mt-20">
      <h3 className="font-bold mb-8 text-3xl">Quick Overview</h3>

      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4  gap-10">
        {Array.isArray(overview) &&
          overview.map((item) => {
            return (
              <div className="overview-services" key={item.id}>
                <div className="flex gap-3 items-center font-semibold text-[#666565]">
                  {/* <i className="fa-solid fa-briefcase" /> */}
                  <h1 className="text-sm">{item.title}</h1>
                </div>
                <p className="mt-2 text-sm  text-[#8f8c8c]">{item.des}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default OverView;
