import React, { useContext, useState } from "react";
import ATSelect from "../input/ATSelect";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { z } from "zod";
import { Col, Row, Select, notification } from "antd";
import type { DatePickerProps } from "antd";
import { DatePicker, Space } from "antd";
import TextArea from "../input/ATTestArea";
import ATInput from "../input/ATInput";
import { bookingSchema } from "../../validation";
import clsx from "clsx";
import Loader from "../Loader";
import emailjs from "@emailjs/browser";
import BookingContext from "../../state";
function BookingFormComponent({ parentClass }: { parentClass?: string }) {
  const { toggleBooleanValue } = useContext(BookingContext)!;
  const [showModel, setShowModel] = useState<boolean>(false);
  const [myDate, setDates] = useState<{
    startDate: string;
    endDate: string;
    dateStart: any;
    dateEnd: any;
  }>({
    startDate: "",
    endDate: "",
    dateStart: null,
    dateEnd: null,
  });
  type ContactUsFormData = z.infer<typeof bookingSchema>;
  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ContactUsFormData>({
    resolver: zodResolver(bookingSchema),
    mode: "onBlur", // "onChange"
  });
  const onChangeStart: DatePickerProps["onChange"] = (date, dateString) => {
    setDates({ ...myDate, startDate: dateString, dateStart: date });
  };
  const onChangeEnd: DatePickerProps["onChange"] = (date, dateString) => {
    setDates({ ...myDate, endDate: dateString, dateEnd: date });
  };

  const sendEmail = (data: any): Promise<void> => {
    const SERVICE_ID = process.env.REACT_APP_EMAIL_SERVICE_ID;
    const TEMPLATE_ID = process.env.REACT_APP_EMAIL_TEMPLATE_BOOKING_ID;
    const USER_ID = process.env.REACT_APP_USER_ID;
    const templateParams = data;

    if (!SERVICE_ID || !TEMPLATE_ID || !USER_ID) {
      notification.error({ message: "Somthing went wrong try again later" });
      // Handle the case where any of the values are undefined (e.g., throw an error or handle it accordingly)

      return Promise.reject("Environment variables are missing.");
    }
    return new Promise((resolve, reject) => {
      emailjs
        .send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
        .then((e: any) => {
          if (e?.status === 200) {
            notification.success({
              message: "Booking made, we will contact you soon",
            });
            reset();
            setDates({
              ...myDate,
              startDate: "",
              endDate: "",
              dateStart: null,
              dateEnd: null,
            });
            resolve();
          } else {
            notification.error({
              message: "Something went wrong please try again later",
            });
            reject(new Error("Email sending failed"));
          }
        })
        .catch((e: any) => {
          notification.error({
            message: e.message,
          });
          reject(e);
        });
    });
  };
  const onSubmit = async (data: any) => {
    toggleBooleanValue("hide");
    setShowModel(true);

    const formData = { ...data, ...myDate };
    try {
      await sendEmail(formData);
    } catch (error) {
      console.log(error);
    } finally {
      setShowModel(false);
    }
  };
  const disabledDate = (current: any) => {
    // Disable dates before the current date
    return current && current < new Date();
  };
  return (
    <>
      <Loader isVisiable={showModel} />
      <form
        className={clsx(
          "contact-form flex flex-col gap-2 justify-start ",
          parentClass
        )}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row gutter={16}>
          <Col span={8}>
            <ATInput name="name" error={errors} register={register} />
          </Col>
          <Col span={8}>
            <ATInput name="email" error={errors} register={register} />
          </Col>
          <Col span={8}>
            <ATInput name="phone" error={errors} register={register} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ATSelect
              name="services"
              register={register}
              error={errors}
              optionArray={[
                "Chopper Service",
                "Flight Ticketing",
                "Travel and Tour",
              ]}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <label className="text-lg font-bold capitalize">Start Date</label>
            <DatePicker
              onChange={onChangeStart}
              value={myDate?.dateStart}
              className="!w-full  !p-3 !mt-2"
              placeholder=""
              disabledDate={disabledDate}
            />
          </Col>
          <Col span={12}>
            <label className="text-lg font-bold capitalize">End Date</label>
            <DatePicker
              onChange={onChangeEnd}
              value={myDate?.dateEnd}
              className="!w-full  !p-3 !mt-2"
              placeholder=""
              disabledDate={disabledDate}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TextArea name="message" register={register} error={errors} />
          </Col>
        </Row>
        <button
          className=" btn primary-outline transaction hover:transaction hover:!bg-light hover:!text-[#055160] font-bold !outline-none !border-none rounded-xl !bg-primary !text-light w-full "
          type="submit"
        >
          Send
        </button>
      </form>
    </>
  );
}

export default BookingFormComponent;
