import { Divider } from "antd";
import React from "react";
import GradidentCircle from "../../../../components/GradidentCircle";

function Includes({ includes, excludes }: any) {
  const hasIncludes = Array.isArray(includes) && includes.length > 0;
  const hasExcludes = Array.isArray(excludes) && excludes.length > 0;

  if (!hasIncludes && !hasExcludes) {
    // If both includes and excludes are empty, do not render anything
    return null;
  }

  return (
    <div className="container mt-10 relative">
      <div
        className="gradident absolute "
        style={{ bottom: -200, right: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      {hasIncludes || hasExcludes ? (
        <h3 className="font-bold mb-4 text-3xl">Includes</h3>
      ) : null}
      <div>
        {hasIncludes &&
          includes.map((item, index) => (
            <p className="flex gap-3 items-baseline" key={index}>
              <i className="fa-solid fa-circle-check text-[#2d8020] ml-7 mb-4 text-[#2d8020] " />{" "}
              {item}
            </p>
          ))}
        {hasExcludes &&
          excludes.map((item, index) => (
            <p className="flex gap-3 items-baseline" key={index}>
              <i className="fa-solid fa-circle-xmark text-[#9e2518] ml-7 mb-4  " />{" "}
              {item}
            </p>
          ))}
      </div>
      <Divider />
    </div>
  );
}

export default Includes;
