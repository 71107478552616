import React, { useContext } from "react";
import HeliImage from "../../../../assets/images/heli image .jpg";
import { useNavigate } from "react-router-dom";
import BookingContext from "../../../../state";

function Card({ ...service }) {
  const navigate = useNavigate();
  const { toggleBooleanValue } = useContext(BookingContext)!;
  return (
    <div className="w-full h-full bg-secondary service-card rounded-xl overflow-hidden transition flex flex-col">
      <div className="card-body transition flex flex-col h-full">
        <div className="h-48 overflow-hidden transition">
          <img
            src={service?.image || HeliImage}
            className="object-cover h-full w-full object-center object-bottom service-card-image"
          />
        </div>
        <div className="text-section px-5 text-style flex-grow">
          <h6 className="text-center my-3 text-[#000] font-bold text-xl">
            {service?.title}
          </h6>
          <p className="">{service?.description}</p>
          <div className="flex flex-col justify-between h-full">
            <div className="mt-5 mb-5 flex items-center justify-center gap-5">
              <button
                className="cta-btn w-full sm:w-auto !bg-light !text-primary !border-primary !border-1"
                onClick={() => navigate(`/${service?.redirect}`)}
              >
                View Details
              </button>
              <button
                className="cta-btn w-full sm:w-auto"
                onClick={() => toggleBooleanValue("show")}
              >
                Make Booking
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
