import React, { useState } from "react";
import { messageSchema } from "../../validation";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import ATInput from "../../components/input/ATInput";
import ATTexttArea from "../../components/input/ATTestArea";
import emailjs from "@emailjs/browser";
import Loader from "../../components/Loader";
import { notification } from "antd";
type ContactUsFormData = z.infer<typeof messageSchema>;
function ContactUsForm() {
  const [showModel, setShowModel] = useState<boolean>(false);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ContactUsFormData>({
    resolver: zodResolver(messageSchema),
    mode: "onBlur", // "onChange"
  });

  const onSubmit = async (data: ContactUsFormData): Promise<void> => {
    setShowModel(true);
    const SERVICE_ID = process.env.REACT_APP_EMAIL_SERVICE_ID;
    const TEMPLATE_ID = process.env.REACT_APP_EMAIL_TEMPLATE_MESSAGE_ID;
    const USER_ID = process.env.REACT_APP_USER_ID;

    if (!SERVICE_ID || !TEMPLATE_ID || !USER_ID) {
      notification.error({ message: "Somthing went wrong try again later" });
      // Handle the case where any of the values are undefined (e.g., throw an error or handle it accordingly)

      return Promise.reject("Environment variables are missing.");
    }

    return new Promise((resolve, reject) => {
      emailjs
        .send(SERVICE_ID, TEMPLATE_ID, data, USER_ID)
        .then((e: any) => {
          if (e?.status === 200) {
            notification.success({
              message: "Message Send, we will contact you soon",
            });
            reset();
            resolve();
            setShowModel(false);
          } else {
            notification.error({
              message: "Something went wrong please try again later",
            });
            reject(new Error("Email sending failed"));
            setShowModel(false);
          }
        })
        .catch((e) => {
          notification.error({
            message: e.message,
          });
          reject(e);
          setShowModel(false);
        })
        .finally(() => {});
    });
  };
  return (
    <>
      <Loader isVisiable={showModel} />
      <form
        className={"contact-form flex flex-col gap-8 justify-start"}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ATInput
          name="name"
          error={errors}
          register={register}
          labelName="text-primary"
          inputClass="!border-primary"
        />
        <ATInput
          name="email"
          error={errors}
          register={register}
          labelName="text-primary"
          inputClass="!border-primary"
        />

        <ATInput
          name="phone"
          error={errors}
          register={register}
          labelName="text-primary"
          inputClass="!border-primary"
        />
        <ATTexttArea
          name="message"
          register={register}
          error={errors}
          labelName="text-primary"
          inputClass="!border-primary"
        />
        <button
          className=" btn primary-outline transaction hover:transaction font-bold !outline-none !border-none rounded-xl !bg-primary !text-light w-full hover:!bg-[#227a49]"
          type="submit"
        >
          Send
        </button>
      </form>
    </>
  );
}

export default ContactUsForm;
