import React from "react";
import FlightImage from "../../../assets/images/Home/flight2.jpg";

import GradidentCircle from "../../../components/GradidentCircle";
function FlightBanner() {
  return (
    <div className="container relative flex items-center flex-col  md:flex-row gap-10 justify-between w-full h-full">
      <div
        className="gradident absolute "
        style={{ top: 0, right: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <div
        className="gradident absolute "
        style={{ bottom: -100, left: -350, zIndex: 4 }}
      >
        <GradidentCircle />
      </div>
      <div className="content-section text-end flex flex-col items-center md:items-end gap-5 justify-center md:w-1/2">
        <h1 className="text-3xl md:text-5xl font-semibold ">
          Domestic Flights{" "}
        </h1>
        <p className="md:pl-20 text-center md:text-end">
          Explore Nepal's beauty with our domestic flights from Kathmandu. Book
          now for an unforgettable adventure!
        </p>
      </div>
      <div className="image-container banner-image md:w-1/2 h-96 order-first md:order-last  rounded-xl ">
        <img src={FlightImage} className="!w-full object-cover" alt="" />
      </div>
    </div>
  );
}

export default FlightBanner;
