import React from "react";
import Layout from "../../layout";
import FlightBanner from "./Components/flightBanner";
import FlightInfo from "./Components/FlightInfo";
import Description from "./Components/description";
import FlightType from "./Components/flightType";

function FlightPage() {
  return (
    <Layout>
      <div className="">
        <FlightBanner />
        <FlightInfo />
        <Description />
        <FlightType />
      </div>
    </Layout>
  );
}

export default FlightPage;
